import { useMemo, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import type { Swiper as SwiperProps } from 'swiper'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { AcBox, AcCard, AcIconButton, AcRow, AcTypography } from '@components'
import { useBreakpoints } from '@hooks/use-breakpoints'
import { AcLuncherWeeklyAttendanceForm } from '@partials'

import { weeks } from './weeks'

import 'swiper/css'

export const AcAttendanceSwiper = () => {
  const [swiperEl, setSwiperEl] = useState<SwiperProps | null>(null)
  const { isMobile } = useBreakpoints()

  const handleNext = () => {
    swiperEl?.slideNext()
  }

  const handlePrev = () => {
    swiperEl?.slidePrev()
  }

  const renderSlides = useMemo(() => {
    if (!weeks.length) return null
    return weeks.map((slide, i) => (
      <SwiperSlide key={i}>
        <AcCard
          maxWidth={448}
          center>
          <AcRow
            pb={5}
            justifyContent="space-between"
            alignItems="flex-start">
            {!isMobile && (
              <AcIconButton
                disabled={i === 0}
                color="green"
                onClick={handlePrev}>
                <ArrowBackIcon />
              </AcIconButton>
            )}
            <AcBox>
              <AcTypography
                color="green.main"
                removePadding
                component="h3">
                {slide.title}
              </AcTypography>
              <AcTypography
                color="#6F6F6F"
                textAlign="center"
                removePadding>
                {slide.weekRange}
              </AcTypography>
            </AcBox>
            {!isMobile && (
              <AcIconButton
                color="green"
                onClick={handleNext}
                disabled={i === weeks.length - 1}>
                <ArrowForwardIcon />
              </AcIconButton>
            )}
          </AcRow>
          <AcLuncherWeeklyAttendanceForm
            year={slide.year}
            week={slide.week}
            deadline={slide.deadline}
          />
        </AcCard>
      </SwiperSlide>
    ))
  }, [handlePrev, handleNext, isMobile])

  return (
    <Swiper
      spaceBetween={12}
      slidesPerView={isMobile ? 1.2 : 1}
      centeredSlides
      modules={[Navigation]}
      onSwiper={setSwiperEl}>
      {renderSlides}
    </Swiper>
  )
}
