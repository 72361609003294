import { createContext } from 'react'
import Api, { IApi } from '@api'
import { action, makeAutoObservable } from 'mobx'

import { saveToStorage } from '@helpers/browser-storage'

import { AuthStore } from './auth.stores'
import { DialogStore } from './dialog.stores'
import { OnboardingStore } from './onboarding.stores'
import { OrdersStore } from './orders.stores'
import { ToasterStore } from './toaster.stores'
import { UserStore } from './user.stores'

export class Store {
  toaster: ToasterStore
  user: UserStore
  onboarding: OnboardingStore
  orders: OrdersStore
  dialog: DialogStore
  auth: AuthStore
  api: IApi

  constructor() {
    makeAutoObservable(this)
    this.api = Api()
    this.auth = new AuthStore(this)
    this.toaster = new ToasterStore(this)
    this.user = new UserStore(this)
    this.onboarding = new OnboardingStore(this)
    this.orders = new OrdersStore(this)
    this.dialog = new DialogStore(this)
  }

  @action
  set<S extends keyof Store, K extends keyof Store[S], A extends Store[S][K]>(
    store: S,
    key: K,
    value: A,
    save?: boolean,
  ) {
    if (!key || !store) return
    ;(this as Store)[store][key] = value
    if (save) saveToStorage(key as string, value)
  }
}

export const StoreContext = createContext<Store>(new Store())
