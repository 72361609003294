export const KEYS = {
  HOME: 'home',
  MIX: 'MIX',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
  RATE_PRODUCT_MORE: 'more',
  RATE_PRODUCT_NICE: 'nice',
  RATE_PRODUCT_LESS: 'les',
  RATE_PRODUCT_REMOVE: 'remove',
} as const

export const STORE_KEYS = {
  TOASTER: 'toaster',
  DIALOG: 'dialog',
  USER: 'user',
  ONBOARDING: 'onboarding',
  ORDERS: 'orders',
  FORM: 'form',
} as const

export const PREFERENCE_KEYS = {
  MIX: 'MIX',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
}
