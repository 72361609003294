import { useMemo } from 'react'

//@ts-ignore
import BasketPink from '@assets/visuals/basket_pink.png'
//@ts-ignore
import TruckGreen from '@assets/visuals/truck_green.png'
import {
  AcBox,
  AcCard,
  AcGridContainer,
  AcGridItem,
  AcImage,
  AcRow,
  AcTypography,
} from '@components'
import { useDateHelpers } from '@hooks/use-date-helpers'
import { IOrderSummary } from '@typings'

export interface IAcDeliveryCard {
  deliveryDetails: IOrderSummary
  isNextWeekDelivery?: boolean
}

export const AcDeliveryCard = ({
  isNextWeekDelivery = false,
  deliveryDetails,
}: IAcDeliveryCard) => {
  const { weekStartDate, weekEndDateFriday } = useDateHelpers(
    deliveryDetails.start_of_week,
  )

  const renderTitle = useMemo(() => {
    return `Week ${deliveryDetails.week} - ${weekStartDate} t/m ${weekEndDateFriday}`
  }, [deliveryDetails])

  return (
    <AcCard
      cardColor={isNextWeekDelivery ? 'pink' : 'green'}
      onClick={() => console.log('onclick')}>
      <AcGridContainer>
        <AcGridItem
          mobile={12}
          tablet={6.5}>
          <AcTypography
            bold
            color={isNextWeekDelivery ? 'orange.main' : 'pink.main'}>
            {renderTitle}
          </AcTypography>
          <AcTypography component="h3">
            {isNextWeekDelivery
              ? 'We gaan aan de slag met het voorbereiden van jouw lokale en duurzame lunch.'
              : 'Je lunch wordt deze week bezorgd'}
          </AcTypography>
          <AcTypography component="span">
            {isNextWeekDelivery
              ? ' Bekijk wat je hebt besteld'
              : 'Bekijk je weekmenu voor deze week en laat ons weten wat je ervan vind.'}
          </AcTypography>
        </AcGridItem>
        <AcGridItem
          mobile={12}
          tablet={4.5}>
          <AcImage
            src={isNextWeekDelivery ? BasketPink : TruckGreen}
            alt="Jouw bestelling"
            sx={{ maxWidth: '100%' }}
          />
        </AcGridItem>
      </AcGridContainer>
    </AcCard>
  )
}
