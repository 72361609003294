import { AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcLuncherAllergiesForm } from '@partials'

export const AcLuncherAllergiesModal = () => {
  return (
    <AcColumn
      height="100%"
      flexGrow={1}>
      <AcTypography>{LABELS.DO_YOU_HAVE_ALLERGIES_SUBTITLE}</AcTypography>
      <AcLuncherAllergiesForm nextStep={false} />
    </AcColumn>
  )
}
