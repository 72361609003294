import { action, computed, makeAutoObservable } from 'mobx'

import { LABELS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import {
  BeforeInstallPromptEvent,
  IRoleEnum,
  IUser,
  IUserResponse,
} from '@typings'

import { ILoginUserPayload } from '../typings/auth.typings'

export interface IUserStore {
  user: IUser
  activeRole: IRoleEnum
}

export class UserStore {
  store: Store
  pwa_event: BeforeInstallPromptEvent | null
  user: IUserStore['user'] | null
  activeRole: IUserStore['activeRole']

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.pwa_event = null
    this.user = {
      id: 1,
      email: 'service@landal.nl',
      first_name: 'Fabian',
      last_name: 'De groot',
      roles: ['ADMIN', 'LUNCHER'],
      permissions: [],
      customer: {
        id: 1,
        name: 'Landal Greenparks BV',
      },
      profile: {
        allergies: [],
        preference: 'MIX',
        default_days: ['monday', 'tuesday', 'thursday'],
      },
    }
    this.activeRole = 'LUNCHER'
  }

  @computed
  get watchUserRoles(): IRoleEnum[] | undefined {
    return this.user?.roles
  }

  @computed
  get watchActiveRole(): IRoleEnum {
    return this.activeRole
  }

  @computed
  get watchPWAEvent(): BeforeInstallPromptEvent | null {
    return this.pwa_event
  }

  @action
  saveDeferredPWAEvent = (event: BeforeInstallPromptEvent) => {
    this.store.set(STORE_KEYS.USER, 'pwa_event', event)
  }

  @action
  attemptPWAInstall = async () => {
    if (this.pwa_event) {
      this.pwa_event.prompt()
      const { outcome } = await this.pwa_event.userChoice
      // The deferredPrompt can only be used once.
      this.store.set(STORE_KEYS.USER, 'pwa_event', null)
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt.')
      } else if (outcome === 'dismissed') {
        console.log('User dismissed the install prompt')
      }
    }
  }

  @action
  setUser = (response: IUserResponse) => {
    this.store.set(STORE_KEYS.USER, 'user', response.user)
  }

  @action
  setRole = (role: IRoleEnum) => {
    // Don't show toaster on initial page load
    if (this.store.user.activeRole !== role) {
      this.store.toaster.warning({
        content: `Je gebruikt de app nu als ${LABELS[role]}`,
      })
    }
    this.store.set(STORE_KEYS.USER, 'activeRole', role)
  }
}
