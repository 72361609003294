import { action, computed, makeAutoObservable } from 'mobx'

import { Store } from '@stores'

import { ILoginUserPayload } from '../typings/auth.typings'

export class AuthStore {
  store: Store
  token: string
  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.token = ''
  }

  @action
  postLogin = async (data: ILoginUserPayload) => {
    try {
      const { token, type } = await this.store.api.auth.login(data)
      // Set token
      return true
    } catch (e) {
      return false
    }
  }

  postRequestPasswordReset = async (email: string) => {
    try {
      await this.store.api.auth.requestPasswordReset({ email })
      return true
    } catch (e) {
      return false
    }
  }
}
