import { AcBox, AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcRequestNewPasswordForm } from '@partials'

export const RequestNewPasswordView = () => {
  return (
    <>
      <AcBox pb={5}>
        <AcTypography
          color="green.main"
          component="h1"
          variant="h2"
          padding="sm">
          {LABELS.FORGOT_PASSWORD}
        </AcTypography>
        <AcTypography removePadding>{LABELS.FORGOT_PASSWORD_TEXT}</AcTypography>
      </AcBox>
      <AcRequestNewPasswordForm />
    </>
  )
}
