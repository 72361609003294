import { AuthApi } from './auth.api'
import { UserApi } from './user.api'

export interface IApi {
  auth: AuthApi
  user: UserApi
}

export default (): IApi => {
  const auth = new AuthApi()
  const user = new UserApi()
  return {
    auth,
    user,
  }
}
