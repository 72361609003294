import { useMemo } from 'react'
import { AcAnimatedFade, AcAnimatedSpring } from '@animations'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { motion } from 'framer-motion'

import { AcCard, AcColumn, AcContainer, AcRow, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import {
  AcLuncherAllergiesModal,
  AcLuncherDefaultAttendanceModal,
  AcLuncherPreferencesModal,
} from '@partials'

export const LuncherPreferencesOverviewView = () => {
  const { dialog } = useStore()
  const links = [
    {
      label: LABELS.DEFAULT_ATTENDANCE,
      title: LABELS.WHEN_WILL_YOU_ATTEND,
      modal: <AcLuncherDefaultAttendanceModal />,
      Icon: ArrowForwardIcon,
      fullScreen: true,
    },
    {
      label: LABELS.PRFERENCES_EXTENDED,
      title: LABELS.WHAT_ARE_YOUR_PREFERENCES,
      modal: <AcLuncherPreferencesModal />,
      Icon: ArrowForwardIcon,
      fullScreen: true,
    },
    {
      label: LABELS.ALLERGIES,
      title: LABELS.DO_YOU_HAVE_ALLERGIES,
      modal: <AcLuncherAllergiesModal />,
      Icon: ArrowForwardIcon,
      fullScreen: true,
    },
  ]

  const renderLinks = useMemo(() => {
    return links.map((link, i) => (
      <AcAnimatedSpring delay={i * 0.1}>
        <motion.div
          style={{ maxWidth: 448, marginInline: 'auto' }}
          whileHover={{ scale: 0.98 }}
          whileTap={{ scale: 0.95 }}>
          <AcCard
            maxWidth={448}
            center
            hideClickArrow
            onClick={() =>
              dialog.open({
                title: link.title,
                content: link.modal,
                maxWidth: 448,
                fullScreen: link.fullScreen,
              })
            }>
            <AcRow
              justifyContent="space-between"
              alignItems="center">
              <AcTypography
                color="green.main"
                removePadding
                bold>
                {link.label}
              </AcTypography>
              <link.Icon sx={{ color: 'green.main' }} />
            </AcRow>
          </AcCard>
        </motion.div>
      </AcAnimatedSpring>
    ))
  }, [links])

  return (
    <AcContainer>
      <AcColumn>
        <AcAnimatedFade>
          <AcColumn
            gap={0}
            textAlign={'center'}>
            <AcTypography
              component="h1"
              color="white.main"
              removePadding>
              {LABELS.PREFERENCES}
            </AcTypography>
            <AcTypography
              color="white.main"
              size="lg">
              {LABELS.WHAT_ARE_YOUR_PREFERENCES}
            </AcTypography>
          </AcColumn>
        </AcAnimatedFade>
        <AcColumn>{renderLinks}</AcColumn>
      </AcColumn>
    </AcContainer>
  )
}
