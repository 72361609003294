import React, { useMemo } from 'react'

//@ts-ignore
import desktopBG from '@assets/visuals/bb_background_desktop.webp'
//@ts-ignore
import mobileBG from '@assets/visuals/bb_background_mobile.png'
//@ts-ignore
import desktopBGLunchTable from '@assets/visuals/bb_background_table.webp'
import { AcBox } from '@components'
import { useBreakpoints } from '@hooks/use-breakpoints'

export interface IAcAppBackground {
  variant: 'branded' | 'branded_table' | 'default'
  children: React.ReactNode
}

export const AcAppBackground = ({ variant, children }: IAcAppBackground) => {
  const { isMobile } = useBreakpoints()

  if (variant === 'default') return children

  const getBackground = useMemo(() => {
    if (variant === 'branded_table') return desktopBGLunchTable
    if (isMobile) return mobileBG
    return desktopBG
  }, [isMobile, variant])

  return (
    <>
      <AcBox
        width="100vw"
        height="100vh"
        sx={{
          backgroundImage: `url(${getBackground})`,
          backgroundSize: 'cover',
          position: 'fixed',
          zIndex: -1,
        }}></AcBox>
      {children}
    </>
  )
}
