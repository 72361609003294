import { forwardRef, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { FONT_WEIGHTS } from 'src/theme/typography.theme'

import { AcButton } from '@components'
import { useBreakpoints } from '@hooks/use-breakpoints'

export interface IAcMenuItem {
  id: number
  href: string
  label: string
  icon: React.ReactNode
}

export const AcMenuItem = forwardRef(
  ({ id, href, label, icon }: IAcMenuItem, ref) => {
    const { isMobile } = useBreakpoints()

    const direction = useMemo(() => {
      if (isMobile) return 'column'
      return 'row'
    }, [isMobile])

    return (
      <NavLink
        to={href}
        tabIndex={-1}>
        {({ isActive }) => (
          <AcButton
            variant="text"
            startIcon={icon}
            sx={theme => ({
              paddingInline: theme.spacing(2),
              display: 'flex',
              flexDirection: direction,
              alignItems: 'center',
              gap: direction === 'row' ? theme.spacing(2) : theme.spacing(1),
              fontWeight: FONT_WEIGHTS.REGULAR,
              height: 52,
              textDecoration: 'none !important',
              '> span': {
                color: `${theme.palette.beige.main} !important`,
                opacity: isActive ? 1 : 0.75,
                fontSize: 12,
              },
              '.MuiButton-icon': {
                color: isActive && `${theme.palette.green.main} !important`,
                backgroundColor: isActive ? theme.palette.beige.main : 'none',
                paddingInline: theme.spacing(3),
                paddingBlock: theme.spacing(1),
                borderRadius: 25,
                margin: 0,
                '.MuiSvgIcon-root': {
                  fontSize: 24,
                },
              },
              '&:hover': {
                '*': {
                  opacity: 1,
                },
              },
            })}>
            {label}
          </AcButton>
        )}
      </NavLink>
    )
  },
)
