import { useMemo } from 'react'
import { Grid, GridProps } from '@mui/material'

import { GAP_SPACING } from '@constants'
import { useBreakpoints } from '@hooks/use-breakpoints'

type Omitted = 'container' | 'item'

export interface IAcGridContainer extends Omit<GridProps, Omitted> {
  spacing?: 'sm' | 'md' | 'lg'
  columnGap?: 'sm' | 'md' | 'lg'
  rowGap?: 'sm' | 'md' | 'lg'
}

export const AcGridContainer = ({
  spacing = 'md',
  columnGap = 'md',
  rowGap = 'md',
  children,
}: IAcGridContainer) => {
  const { currentViewport } = useBreakpoints()
  const gapSpacing = useMemo(() => {
    return GAP_SPACING[currentViewport]
  }, [currentViewport])

  return (
    <Grid
      container
      gap={gapSpacing[spacing]}>
      {children}
    </Grid>
  )
}
