/* eslint-disable max-len */

import { ThemeOptions } from '@mui/material'

import { THEME_COLORS } from '@constants'

export const FONT_FAMILIES = {
  ROCA: "'RocaTwo', 'Avenir', Helvetica, Arial, sans-serif;",
  POSTERAMA: "'Posterama', 'Avenir', Helvetica, Arial, sans-serif;",
  ROCA_SHORT: 'RocaTwo',
  POSTERAMA_SHORT: 'Posterama',
}

export const FONT_WEIGHTS = {
  REGULAR: 400,
  BOLD: 600,
}

export const BASE_FONT_SIZE = 16

export const calcFontSize = (inputPx: number) => {
  return `${inputPx / BASE_FONT_SIZE}rem`
}

export const TYPOGRAPHY: ThemeOptions['typography'] = {
  h1: {
    fontFamily: FONT_FAMILIES.ROCA,
    fontWeight: FONT_WEIGHTS.BOLD,
    color: THEME_COLORS.BRAND_GREEN,
    lineHeight: 1.2,
    fontSize: `${calcFontSize(26)} !important`,
    '@media (min-width:768px)': {
      fontSize: `${calcFontSize(28)} !important`,
    },
    '@media (min-width:1200px)': {
      fontSize: `${calcFontSize(32)} !important`,
    },
  },
  h2: {
    fontFamily: FONT_FAMILIES.ROCA,
    fontWeight: FONT_WEIGHTS.BOLD,
    lineHeight: 1.2,
    fontSize: `${calcFontSize(22)} !important`,
    '@media (min-width:768px)': {
      fontSize: `${calcFontSize(24)} !important`,
    },
    '@media (min-width:1200px)': {
      fontSize: `${calcFontSize(26)} !important`,
    },
  },
  h3: {
    fontFamily: FONT_FAMILIES.ROCA,
    fontWeight: FONT_WEIGHTS.BOLD,
    lineHeight: 1.2,
    fontSize: `${calcFontSize(20)} !important`,
    '@media (min-width:768px)': {
      fontSize: `${calcFontSize(22)} !important`,
    },
    '@media (min-width:1200px)': {
      fontSize: `${calcFontSize(22)} !important`,
    },
  },
  h4: undefined,
  h5: undefined,
  h6: undefined,
  button: {
    fontFamily: FONT_FAMILIES.POSTERAMA,
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: 1,
    textTransform: 'initial',
  },
  p: {
    fontFamily: FONT_FAMILIES.POSTERAMA,
    fontWeight: FONT_WEIGHTS.REGULAR,
    fontSize: '1rem',
    lineHeight: 1.3,
    letterSpacing: 0,
  },
  span: {
    fontFamily: FONT_FAMILIES.POSTERAMA,
    fontWeight: FONT_WEIGHTS.REGULAR,
    fontSize: '1rem',
    lineHeight: 1.3,
    letterSpacing: 0,
  },
  fontFamily: FONT_FAMILIES.POSTERAMA,
  htmlFontSize: BASE_FONT_SIZE,
  fontSize: BASE_FONT_SIZE,
  fontWeightRegular: FONT_WEIGHTS.REGULAR,
  fontWeightBold: FONT_WEIGHTS.BOLD,
}
