import { FormControl, FormLabel, RadioGroup } from '@mui/material'

import { AcRadioContained, AcRadioDefault } from '@components'

export interface IRadioGroupOption {
  title?: string
  label: string
  value: string | number
}

export interface IRadioGroup {
  id: string
  label?: string
  options: IRadioGroupOption[]
  variant?: 'default' | 'contained'
  fullWidth?: boolean
  value?: string | number
  onChange?: (value: IRadioGroup['value']) => void
}

export const AcRadioGroup = ({
  id,
  label,
  options,
  variant = 'contained',
  fullWidth = false,
  value,
  onChange,
}: IRadioGroup) => {
  const RadioEl = variant === 'contained' ? AcRadioContained : AcRadioDefault

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <FormLabel
          color="black"
          id={id}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        value={value}
        onChange={e => onChange && onChange(e.target.value)}
        aria-labelledby={id}
        name="radio-buttons-group">
        {options.map(option => (
          <RadioEl {...option} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
