import { Link, useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'

import {
  AcBox,
  AcButton,
  AcColumn,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
  AcSelect,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

import { TOASTERS } from '../../../constants/toasters.constants'

export const AcLoginForm = () => {
  const { auth, toaster } = useStore()
  const navigate = useNavigate()

  const onSubmit = async (data: any) => {
    const success = await auth.postLogin(data)
    if (!success) {
      toaster.error(TOASTERS.LOGIN_FAILED)
      return
    }

    navigate(NAVIGATE_TO.ADMIN_DASHBOARD)
    toaster.success(TOASTERS.LOGIN_SUCCESSFUL)
  }

  return (
    <AcFormProvider
      onSubmit={onSubmit}
      flexGrow={1}
      initial={[]}>
      <AcFormInput
        register="email"
        Component={
          <AcEmailInput
            label={LABELS.EMAIL_ADDRESS}
            required
          />
        }
      />
      <AcFormInput
        register="password"
        Component={
          <AcPasswordInput
            label={LABELS.PASSWORD}
            required
          />
        }
      />

      <AcColumn
        spacing="xl"
        pt={3}
        flexGrow={1}>
        <AcTypography removePadding>
          <Link to={NAVIGATE_TO.REQUEST_NEW_PASSWORD}>
            {LABELS.FORGOT_PASSWORD}
          </Link>
        </AcTypography>

        <AcButton
          variant="primary"
          type="submit"
          fullWidth
          sx={{ marginTop: 'auto' }}>
          {LABELS.NEXT}
        </AcButton>
      </AcColumn>
    </AcFormProvider>
  )
}
