import { useMemo } from 'react'

import { AdminNavigation, LuncherNavigation } from '@constants'

import { useStore } from './use-store'

export const useRoleToggle = () => {
  const { user } = useStore()
  const navigation = useMemo(() => {
    if (user.watchActiveRole === 'ADMIN') return AdminNavigation
    return LuncherNavigation
  }, [user.watchActiveRole])

  return { activeRole: user.watchActiveRole, navigation }
}
