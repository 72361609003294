import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import {
  AcContainer,
  AcDashboardWeekTab,
  AcTabs,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { getWeeks } from '@helpers/get-weeks'
import { useGreeting } from '@hooks/use-greeting.hooks'

export const DashboardView = observer(() => {
  const greeting = useGreeting()
  const tabs = useMemo(() => {
    return getWeeks(4).map(week => ({
      title: `${LABELS.WEEK} ${week}`,
      content: <AcDashboardWeekTab week={week} />,
    }))
  }, [])

  return (
    <AcContainer wide>
      <AcTypography
        removePadding
        component="h1">
        {greeting} Severin
      </AcTypography>
      <AcTabs tabs={tabs} />
    </AcContainer>
  )
})
