import { useMemo } from 'react'
import { BoxProps } from '@mui/material'

import { AcBox } from '@components'
import { GAP_SPACING } from '@constants'
import { useBreakpoints } from '@hooks/use-breakpoints'

type Omitted = 'display' | 'flexDirection'

export interface IAcColumn extends Omit<BoxProps, Omitted> {
  spacing?: 'sm' | 'md' | 'lg' | 'xl'
}

export const AcColumn = ({ spacing = 'md', children, ...props }: IAcColumn) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const gapSpacing = useMemo(() => {
    if (isMobile) return GAP_SPACING.MOBILE
    if (isTablet) return GAP_SPACING.TABLET
    return GAP_SPACING.DESKTOP
  }, [isMobile, isTablet, isDesktop])

  return (
    <AcBox
      display="flex"
      flexDirection="column"
      gap={gapSpacing[spacing]}
      {...props}>
      {children}
    </AcBox>
  )
}
