import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { InputAdornment } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { AcCheckboxDefault, AcHelpertext, AcRow, AcTag } from '@components'

export interface IAcSelectOption {
  id: number | string
  value: string
  label: string
}

export interface IAcSelectBase {
  id: string
  label: string
  fullWidth?: boolean
  options: IAcSelectOption[]
  startIcon?: React.ReactNode
  disabled?: boolean
  helperText?: string
  error?: string
  onChange: (value: string | string[]) => void
}

export interface IAcSelectSingle extends IAcSelectBase {
  allowMultiple?: false
  value: string
}

export interface IAcSelectMultiple extends IAcSelectBase {
  allowMultiple?: true
  value: string[]
}

export type IAcSelect = IAcSelectSingle | IAcSelectMultiple

export const AcSelect = ({
  id,
  label,
  value,
  fullWidth = true,
  options,
  onChange,
  startIcon,
  disabled,
  helperText,
  error,
  allowMultiple = false,
}: IAcSelect) => {
  const [val, setVal] = useState(value)

  useEffect(() => {
    if (value !== val) onChange(val)
  }, [val])

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        id={id}
        value={val}
        label={label}
        onChange={e => setVal(e.target.value)}
        disabled={disabled}
        multiple={allowMultiple}
        IconComponent={ExpandMoreIcon}
        renderValue={(selected: string | string[]) => (
          <AcRow gap={1}>
            {typeof selected === 'string'
              ? selected
              : selected.map(item => <AcTag label={item} />)}
          </AcRow>
        )}
        startAdornment={
          startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : null
        }
        MenuProps={{
          sx: {
            '.MuiMenu-paper': {
              backgroundColor: 'white.main',
            },
          },
        }}
        sx={{
          outlineColor: error && 'error.main',
          outlineWidth: error && 2,
        }}>
        {options.map(option => (
          <MenuItem
            key={option.id}
            value={option.value}
            sx={theme => ({
              fontSize: theme.typography.fontSize,
              maxHeight: theme.spacing(4),
              paddingBlock: theme.spacing(2),
            })}>
            {allowMultiple ? (
              <AcCheckboxDefault
                value={option.value}
                label={option.label}
                checked={val.includes(option.value)}
              />
            ) : (
              option.label
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <AcHelpertext text={helperText} />}
      {error && (
        <AcHelpertext
          text={error}
          isError
        />
      )}
    </FormControl>
  )
}
