import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'

import { IAuthLoginResponse } from '../typings/auth.typings'

export class AuthApi extends AxiosBaseApi {
  login = (payload: { email: string; password: string }) =>
    this.api
      .post<{
        data: { meta: IAuthLoginResponse }
      }>(ENDPOINTS.AUTHENTICATION.LOGIN, payload)
      .then(response => response.data.meta)

  register = (payload: {
    name: string
    email: string
    password: string
    password_confirm: string
    token: string
  }) =>
    this.api.post(ENDPOINTS.AUTHENTICATION.LOGIN, {
      payload,
    })

  requestPasswordReset = (payload: { email: string }) =>
    this.api
      .post(ENDPOINTS.AUTHENTICATION.REQUEST_NEW_PASSWORD, payload)
      .then(response => console.log(response))

  resetPassword = (payload: {
    email: string
    password: string
    password_confirm: string
    token: string
  }) => this.api.post(ENDPOINTS.AUTHENTICATION.RESET_PASSWORD, payload)

  changePassword = (payload: {
    old_password: string
    new_password: string
    new_password_confirm: string
  }) => this.api.post(ENDPOINTS.AUTHENTICATION.RESET_PASSWORD, payload)
}
