import {
  AcButton,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
} from '@components'
import { LABELS } from '@constants'
import { useBreakpoints } from '@hooks/use-breakpoints'

export const AcResetPasswordForm = () => {
  const onSubmit = (values: any) => {
    console.log(values)
  }

  const { isMobile } = useBreakpoints()

  return (
    <AcFormProvider
      initial={[]}
      onSubmit={onSubmit}
      flexGrow={1}>
      <AcFormInput
        register={'password'}
        Component={
          <AcPasswordInput
            allowVisible
            label={LABELS.PASSWORD}
            required
          />
        }
      />
      <AcFormInput
        lastChild
        register={'password_confirm'}
        Component={
          <AcPasswordInput
            allowVisible
            label={LABELS.CONFIRM_PASSWORD}
            required
          />
        }
      />
      <AcButton
        type="submit"
        variant="primary"
        sx={{ marginTop: isMobile ? 'auto' : 6 }}
        fullWidth>
        {LABELS.CHANGE_PASSWORD_BUTTON}
      </AcButton>
    </AcFormProvider>
  )
}
