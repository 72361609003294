export const weeks = [
  {
    title: 'Volgende week',
    weekRange: '17 juni t/m 23 juni',
    confirmText: 'Bevestigen kan tot 14 juni 12:00',
    year: 2024,
    week: 25,
    deadline: '2011-10-10T14:48:00.000+09:00',
  },
  {
    title: 'Over 2 weken',
    weekRange: '24 juni t/m 1 july',
    confirmText: 'Bevestigen kan tot 23 juni 12:00',
    year: 2024,
    week: 25,
    deadline: '2011-10-10T14:48:00.000+09:00',
  },
  {
    title: 'Over 3 weken',
    weekRange: '17 juni t/m 23 juni',
    confirmText: 'Bevestigen kan tot 14 juni 12:00',
    year: 2024,
    week: 25,
    deadline: '2011-10-10T14:48:00.000+09:00',
  },
]
