import { useEffect, useMemo, useState } from 'react'
import { AcAnimatedFade } from '@animations'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined'

// @ts-ignore
import soupSaladBG from '@assets/visuals/soup_salad_card_bg.png'
import {
  AcButton,
  AcCard,
  AcCharts,
  AcColumn,
  AcExtrasSwiper,
  AcIconButton,
  AcImage,
  AcRow,
  AcTag,
  AcTypography,
} from '@components'
import { DIALOGS, LABELS, THEME_COLORS } from '@constants'
import { useStore } from '@hooks/use-store'
import { useBreakpoints } from '@utilities/hooks'

interface IAcDashboardWeekTab {
  week: number
}

export const AcDashboardWeekTab = ({ week }: IAcDashboardWeekTab) => {
  const { orders, dialog } = useStore()
  const [doubleChart, setDoubleChart] = useState<any>(null)

  const { isMobile } = useBreakpoints()

  const fetchCharts = () => {
    const double = orders.fetchMockchartDouble()
    if (double) setDoubleChart(double)
  }

  const flexDirection = useMemo(() => {
    return isMobile ? 'column' : 'row'
  }, [isMobile])

  const alignItems = useMemo(() => {
    return isMobile ? 'flex-start' : 'center'
  }, [isMobile])

  const lunchPreferencesButton = useMemo(() => {
    if (isMobile) {
      return (
        <AcIconButton
          sx={{ padding: 0 }}
          color="green">
          <TroubleshootOutlinedIcon />
        </AcIconButton>
      )
    }

    return (
      <AcButton
        startIcon={<TroubleshootOutlinedIcon />}
        variant="text">
        {LABELS.LUNCH_PREFERENCES}
      </AcButton>
    )
  }, [isMobile])

  const reminderButton = useMemo(() => {
    if (isMobile) {
      return (
        <AcIconButton
          sx={{ padding: 0 }}
          onClick={() => dialog.open(DIALOGS.SEND_REMINDER)}
          color="green">
          <NotificationsActiveOutlinedIcon />
        </AcIconButton>
      )
    }

    return (
      <AcButton
        startIcon={<NotificationsActiveOutlinedIcon />}
        onClick={() => dialog.open(DIALOGS.SEND_REMINDER)}
        variant="text">
        {LABELS.SEND_REMINDER}
      </AcButton>
    )
  }, [isMobile])

  useEffect(() => {
    fetchCharts()
  }, [])

  return (
    <AcAnimatedFade
      enter={{ x: 0, y: 20 }}
      exit={{ x: 0, y: -20 }}>
      <AcColumn gap={6}>
        <AcColumn>
          <AcRow
            gap={0}
            alignItems={alignItems}
            sx={{ flexDirection, justifyContent: 'space-between' }}>
            <AcTypography
              color="green.main"
              removePadding
              component="h2">
              {LABELS.ORDER_WEEK} {week}
            </AcTypography>
            <AcRow
              gap={5}
              alignItems={alignItems}
              sx={{ flexDirection }}>
              <AcTypography removePadding>
                {LABELS.FINALIZE_BY} donderdag 13 juni 14:00
              </AcTypography>
              <AcButton>{LABELS.START_ORDERING}</AcButton>
            </AcRow>
          </AcRow>

          <AcRow
            gap="16px"
            sx={{ flexDirection }}
            justifyContent="space-between">
            <AcCard
              sx={{
                flex: 2,
              }}>
              <AcColumn gap={5}>
                <AcRow
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <AcTypography
                    color="green.main"
                    component="h2">
                    {LABELS.AMOUNTS}
                  </AcTypography>
                  <AcRow gap={5}>
                    {lunchPreferencesButton}
                    {reminderButton}
                  </AcRow>
                </AcRow>
                <AcRow gap={6}>
                  <AcColumn gap={0}>
                    <AcTypography
                      removePadding
                      color="green.main"
                      component="h3">
                      30
                    </AcTypography>
                    <AcTypography color="green.main">
                      {LABELS.AMOUNT_REGISTERED.toLowerCase()}
                    </AcTypography>
                  </AcColumn>
                  <AcColumn gap={0}>
                    <AcTypography
                      removePadding
                      color="green.main"
                      component="h3">
                      40%
                    </AcTypography>
                    <AcTypography color="green.main">
                      {LABELS.CONFIRMED.toLowerCase()}
                    </AcTypography>
                  </AcColumn>
                </AcRow>
                {doubleChart && <AcCharts chartData={doubleChart} />}
              </AcColumn>
            </AcCard>
            <AcCard
              removePadding
              sx={{
                flex: 1,
                border: 0,
                backgroundColor: THEME_COLORS.BRAND_PINK,
              }}
              cardColor="pink">
              <AcColumn
                justifyContent="space-between"
                sx={{ height: '100%' }}>
                <AcColumn
                  padding={5}
                  gap={2}>
                  <AcTypography
                    removePadding
                    component="h2">
                    {LABELS.WEEK_MENU}
                  </AcTypography>
                  <AcRow gap={2}>
                    <AcTag
                      large
                      color="white"
                      label="Salade"
                    />
                    <AcTag
                      large
                      color="white"
                      label="Soep"
                    />
                  </AcRow>
                </AcColumn>
                <AcImage
                  sx={{ float: 'right', borderRadius: '8px', maxWidth: '100%' }}
                  src={soupSaladBG}
                />
              </AcColumn>
            </AcCard>
          </AcRow>
        </AcColumn>

        <AcColumn>
          <AcColumn gap={0}>
            <AcTypography
              removePadding
              color="green.main"
              component="h2">
              {LABELS.EXTRAS}
            </AcTypography>
            {!isMobile && (
              <AcTypography removePadding>{LABELS.EXTRAS_TEXT}</AcTypography>
            )}
          </AcColumn>
          <AcExtrasSwiper />
        </AcColumn>
      </AcColumn>
    </AcAnimatedFade>
  )
}
