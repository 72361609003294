import { Fade, Menu } from '@mui/material'

export interface IAcCustomerSettingsDropdown {
  open: null | HTMLElement
  handleClose: () => void
  children: React.ReactNode
}

export const AcCustomerSettingsDropdown = ({
  open,
  handleClose,
  children,
}: IAcCustomerSettingsDropdown) => {
  return (
    <Menu
      id="navbar-dropdown"
      anchorEl={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        top: 50,
        '.MuiList-root': {
          minWidth: 360,
        },
      }}
      open={Boolean(open)}
      onClose={handleClose}
      TransitionComponent={Fade}>
      {children}
    </Menu>
  )
}
