import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AcBackButton } from 'src/components/core/ac-back-button/ac-back-button'

import {
  AcCard,
  AcColumn,
  AcContainer,
  AcOrderDetailsMenuTab,
  AcOrderDetailsOverviewTab,
  AcTabs,
  AcTypography,
} from '@components'
import { useStore } from '@hooks/use-store'

export const OrderDetailView = observer(() => {
  const { orders } = useStore()
  const [singleChart, setSingleChart] = useState<any>(null)
  useEffect(() => {
    const single = orders.fetchMockchartSingle()
    if (single) setSingleChart(single)
  }, [])

  const renderTabs = useMemo(() => {
    return [
      {
        title: 'Overzicht',
        content: <AcOrderDetailsOverviewTab singleChart={singleChart} />,
      },
      {
        title: 'Weekmenu',
        content: <AcOrderDetailsMenuTab />,
      },
    ]
  }, [singleChart])

  return (
    <AcContainer>
      <AcBackButton
        variant="text"
        withIcon
      />
      <AcColumn mt={6}>
        <AcCard cardColor="pink">
          <AcTypography component="h1">Bestelling week 25</AcTypography>
          <AcTypography removePadding>
            De bestelling kan niet meer gewijzigd worden
          </AcTypography>
        </AcCard>
        <AcTabs tabs={renderTabs} />
      </AcColumn>
    </AcContainer>
  )
})
