import { createTheme } from '@mui/material'

import { SPACING } from '@constants'

import { BREAKPOINTS } from './breakpoints.theme'
import { COMPONENTS } from './components.theme'
import { PALETTE_LIGHT } from './palette.theme'
import { SHADOWS } from './shadows.theme'
import { TRANSITIONS } from './transitions.theme'
import { TYPOGRAPHY } from './typography.theme'

export const DEFAULT_THEME = createTheme({
  breakpoints: BREAKPOINTS,
  components: COMPONENTS,
  palette: PALETTE_LIGHT,
  shadows: SHADOWS,
  transitions: TRANSITIONS,
  typography: TYPOGRAPHY,
  spacing: SPACING,
})
