import { FormControlLabel as MuiFormControlLabel, styled } from '@mui/material'

export const AcFormlabelWrapper = styled(MuiFormControlLabel)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.white.main,
  border: `1px solid ${theme.palette['light-green'].main}`,
  borderRadius: 4,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: theme.spacing(3),
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  color: theme.palette.black.main,
  transition: 'all 0.2s ease',
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(5),
  },
  '[data-name="ac-radio-title"]': {
    color: theme.palette.green.main,
  },
  '.MuiSvgIcon-root': {
    color: theme.palette.green.main,
  },
  '&:has(.Mui-checked)': {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    '[data-name="ac-radio-title"]': {
      color: theme.palette.white.main,
    },
    '.MuiSvgIcon-root': {
      color: theme.palette.yellow.main,
    },
  },
  '&:hover': {
    border: `1px solid ${theme.palette['green'].main}`,
  },
  '&:focus-within': {
    borderColor: theme.palette.white.main,
    outline: `2px solid ${theme.palette.orange.main}`,
  },
}))
