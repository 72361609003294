import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(isoWeek)

export interface IUseDateHelpersOptions {
  locale?: string
  dateOptions?: Intl.DateTimeFormatOptions
}

export const useDateHelpers = (
  input?: string,
  options?: IUseDateHelpersOptions,
) => {
  const currentWeek = dayjs().isoWeek()

  if (!input) {
    return {
      currentWeek,
    }
  }

  const date = new Date(input)
  const locale = options?.locale || 'nl-NL'
  const dateOptions: Intl.DateTimeFormatOptions = options?.dateOptions || {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }

  const formatter = new Intl.DateTimeFormat(locale, dateOptions)

  const prettyDate = formatter.format(date)
  const weekDay = date.toLocaleDateString(locale, { weekday: 'long' })
  const renderDate = date.toLocaleDateString(locale, { day: '2-digit' })
  const month = date.toLocaleDateString(locale, { month: 'long' })
  const year = date.toLocaleDateString(locale, { weekday: 'long' })
  const weekStartDate = dayjs(input).isoWeekday(1).toDate()
  const weekEndDateFriday = dayjs(input).isoWeekday(5).toDate()
  const weekEndDateSunday = dayjs(input).isoWeekday(7).toDate()

  return {
    prettyDate,
    weekDay,
    date: renderDate,
    month,
    year,
    weekStartDate: formatter.format(weekStartDate),
    weekEndDateFriday: formatter.format(weekEndDateFriday),
    weekEndDateSunday: formatter.format(weekEndDateSunday),
    currentWeek,
  }
}
