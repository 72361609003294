import { Box } from '@mui/material'

import { useBreakpoints } from '@hooks/use-breakpoints'

interface IAcSection {
  children: React.ReactNode
}

export const AcSection = ({ children }: IAcSection) => {
  const { isTablet } = useBreakpoints()

  return (
    <Box
      component="section"
      py={isTablet ? 7 : 6}>
      {children}
    </Box>
  )
}
