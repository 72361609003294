import { ButtonProps } from '@mui/material'

import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'
import { TextButton } from './TextButton'
import { WhiteButton } from './WhiteButton'

type Omitted = 'color' | 'variant'

export interface IAcButton extends Omit<ButtonProps, Omitted> {
  variant?: 'primary' | 'secondary' | 'text' | 'white'
  children?: React.ReactNode
}

export const AcButton = ({
  variant = 'primary',
  children,
  ...props
}: IAcButton) => {
  if (variant === 'text') {
    return (
      <TextButton
        variant="text"
        color="green"
        {...props}>
        <span>{children}</span>
      </TextButton>
    )
  }

  if (variant === 'secondary') {
    return (
      <SecondaryButton
        variant="outlined"
        color="green"
        {...props}>
        <span>{children}</span>
      </SecondaryButton>
    )
  }

  if (variant === 'white') {
    return (
      <WhiteButton
        color="white"
        variant="contained"
        {...props}>
        <span>{children}</span>
      </WhiteButton>
    )
  }

  return (
    <PrimaryButton
      variant="contained"
      color="yellow"
      {...props}>
      <span>{children}</span>
    </PrimaryButton>
  )
}
