import ErrorIcon from '@mui/icons-material/Error'

import { AcBox, AcButton, AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'

import { calcFontSize } from '../../theme/typography.theme'

export const NoAccessView = () => {
  return (
    <>
      <AcColumn
        alignItems="center"
        textAlign="center"
        spacing="xl">
        <ErrorIcon
          color="error"
          sx={{
            fontSize: calcFontSize(80),
          }}
        />
        <AcBox>
          <AcTypography
            color="green.main"
            component="h1"
            variant="h2"
            padding="sm">
            {LABELS.NO_ACCESS}
          </AcTypography>
          <AcTypography removePadding>{LABELS.NO_ACCESS_TEXT}</AcTypography>
        </AcBox>

        <AcButton
          fullWidth
          variant="primary">
          {LABELS.BACK_TO_LOGIN}
        </AcButton>
      </AcColumn>
    </>
  )
}
