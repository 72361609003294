import { AcTypography } from '@components'

export interface IAcHelperText {
  text: string
  isError?: boolean
}

export const AcHelpertext = ({ text, isError }: IAcHelperText) => {
  return (
    <AcTypography
      color={isError ? 'error' : 'green.main'}
      component="span"
      size="xs">
      {text}
    </AcTypography>
  )
}
