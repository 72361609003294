import { AcLunchReminderForm } from '@partials'

export const DIALOGS = {
  SEND_REMINDER: {
    title: 'Herinnering sturen',
    subTitle:
      "Stuur een bericht om collega's te herinneren zich aan te melden voor de lunch",
    content: <AcLunchReminderForm />,
    maxWidth: 600,
  },
}
