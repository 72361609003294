import { useMediaQuery, useTheme } from '@mui/material'

export type ViewportOptions = 'MOBILE' | 'TABLET' | 'DESKTOP'

export const useBreakpoints = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
  const isTablet = useMediaQuery(theme.breakpoints.between('tablet', 'desktop'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'))

  const currentViewport: ViewportOptions = isMobile
    ? 'MOBILE'
    : isTablet
      ? 'TABLET'
      : 'DESKTOP'

  return {
    isMobile,
    isTablet,
    isDesktop,
    currentViewport,
  }
}
