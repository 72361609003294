import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { Store } from '@stores'

export interface IAcToasterArgs {
  title?: string
  content: string
  timeout?: number
}

export class ToasterStore {
  store: Store
  isOpen: boolean
  severity: 'success' | 'warning' | 'error'
  title: IAcToasterArgs['title']
  content: IAcToasterArgs['content']
  timeout: IAcToasterArgs['timeout']

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.isOpen = false
    this.severity = 'success'
    this.title = ''
    this.content = ''
    this.timeout = 0
  }

  @computed
  get is_open(): boolean {
    return this.isOpen
  }

  @action
  close = () => {
    this.store.set(STORE_KEYS.TOASTER, 'isOpen', false)
    setTimeout(() => {
      this.store.set(STORE_KEYS.TOASTER, 'content', '')
      this.store.set(STORE_KEYS.TOASTER, 'title', '')
      this.store.set(STORE_KEYS.TOASTER, 'severity', 'success')
      this.store.set(STORE_KEYS.TOASTER, 'timeout', 0)
    }, 500)
  }

  @action
  success = ({ title, content, timeout }: IAcToasterArgs) => {
    this.store.set(STORE_KEYS.TOASTER, 'title', title)
    this.store.set(STORE_KEYS.TOASTER, 'content', content)
    this.store.set(STORE_KEYS.TOASTER, 'severity', 'success')
    this.store.set(STORE_KEYS.TOASTER, 'isOpen', true)
    if (timeout) {
      this.store.set(STORE_KEYS.TOASTER, 'timeout', timeout)
    }
  }

  @action
  warning = ({ title, content, timeout }: IAcToasterArgs) => {
    this.store.set(STORE_KEYS.TOASTER, 'title', title)
    this.store.set(STORE_KEYS.TOASTER, 'content', content)
    this.store.set(STORE_KEYS.TOASTER, 'severity', 'warning')
    this.store.set(STORE_KEYS.TOASTER, 'isOpen', true)
    if (timeout) {
      this.store.set(STORE_KEYS.TOASTER, 'timeout', timeout)
    }
  }

  @action
  error = ({ title, content, timeout }: IAcToasterArgs) => {
    this.store.set(STORE_KEYS.TOASTER, 'title', title)
    this.store.set(STORE_KEYS.TOASTER, 'content', content)
    this.store.set(STORE_KEYS.TOASTER, 'severity', 'error')
    this.store.set(STORE_KEYS.TOASTER, 'isOpen', true)
    if (timeout) {
      this.store.set(STORE_KEYS.TOASTER, 'timeout', timeout)
    }
  }
}
