import { Button, styled } from '@mui/material'

export const TextButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  textDecoration: 'underline',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  height: 24,
  '&:hover': {
    color: theme.palette.black.main,
  },
  '&.Mui-disabled': {
    color: theme.palette['grey-20'].main,
  },
}))
