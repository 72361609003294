import { Box } from '@mui/material'

interface IAcImage {
  src: string
  alt?: string
  [x: string]: any
}

export const AcImage = ({ src, alt, ...props }: IAcImage) => {
  return (
    <Box
      component="img"
      alt={alt}
      src={src}
      {...props}
      sx={{
        maxWidth: props?.sx?.maxWidth || '100%',
      }}
    />
  )
}
