import { AcChartBarDouble, AcChartBarSingle } from '@components'

export interface IAcChartDataset {
  datasetName?: string
  datasetColor?: string
  data: number[]
}

export interface IAcChartData {
  type: 'single' | 'double'
  categories: string[]
  datasets: IAcChartDataset[]
}

export interface IAcCharts {
  chartData: IAcChartData
}

export const AcCharts = ({ chartData }: IAcCharts) => {
  if (chartData.type === 'double') return <AcChartBarDouble {...chartData} />

  return <AcChartBarSingle {...chartData} />
}
