import React from 'react'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { render } from 'preact'
import { DEFAULT_THEME } from 'src/theme/index.theme'

import 'preact/debug'

import { AcRouter } from './routes/ac-router.routes'

import './styles/global.scss'

const root = document.getElementById('root') as HTMLElement
const globalStyles = (
  <GlobalStyles
    styles={{
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    }}
  />
)

render(
  <React.StrictMode>
    {globalStyles}
    <ThemeProvider theme={DEFAULT_THEME}>
      <CssBaseline />
      <AcRouter />
    </ThemeProvider>
  </React.StrictMode>,
  root,
)
