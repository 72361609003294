import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'

// @ts-ignore
import { ReactComponent as DayEdit } from '@assets/custom-icons/day-edit.svg'
// @ts-ignore
import { ReactComponent as PersonEdit } from '@assets/custom-icons/person-edit.svg'

export const ICON_ELEMENTS = {
  BACK: ArrowBack,
  NEXT: ArrowForward,
}

export const CUSTOM_ICON_ELEMENTS = {
  PERSON_EDIT: PersonEdit,
  DAY_EDIT: DayEdit,
}

export const ICONS = {
  BACK: 'BACK',
  NEXT: 'NEXT',
  PERSON_EDIT: 'PERSON_EDIT',
  DAY_EDIT: 'DAY_EDIT',
}
