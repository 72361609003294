import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import type { IRadioGroupOption } from '../ac-radio-group/ac-radio-group'

export const AcRadioDefault = ({ label, value }: IRadioGroupOption) => {
  return (
    <FormControlLabel
      value={value}
      label={label}
      sx={({ palette }) => ({
        color: palette.green.main,
      })}
      control={
        <Radio
          checkedIcon={<CheckCircleIcon />}
          sx={({ palette }) => ({
            color: palette.green.main,
            '&.Mui-checked': {
              color: palette.green.main,
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${palette.orange.main}`,
              outlineOffset: -10,
              borderRadius: 25,
            },
          })}
        />
      }
    />
  )
}
