import { useEffect } from 'react'
import { NAVIGATE_TO } from '@routes'

//@ts-ignore
import PWAPromptLogo from '@assets/visuals/mijn-bb-pwa-prompt.png'
import { AcButton, AcCard, AcColumn, AcRow, AcTypography } from '@components'
import { usePWAInstall } from '@hooks/use-pwa-install.hooks'

export const AcPWAPrompt = () => {
  const { checkForInstalledApps, attemptPWAInstall } = usePWAInstall()

  useEffect(() => {
    checkForInstalledApps()
  }, [])

  return (
    <AcCard maxWidth={400}>
      <AcColumn spacing="lg">
        <AcRow>
          <img
            src={PWAPromptLogo}
            width={70}
            height={72}
            alt="PWA logo"
          />
          <AcTypography>
            Zet deze app op je homescreen, zodat je er altijd snel bij kan.
          </AcTypography>
        </AcRow>
        <AcColumn>
          <AcButton onClick={attemptPWAInstall}>
            Toevoegen aan homescreen
          </AcButton>
          <AcButton
            href={NAVIGATE_TO.LUNCHER_OVERVIEW}
            variant="text">
            Direct naar app
          </AcButton>
        </AcColumn>
      </AcColumn>
    </AcCard>
  )
}
