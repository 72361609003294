import { useMemo } from 'react'

import { AcTable } from '@components'
import { IOrderSummary } from '@typings'

import { AcDeliveriesTableRow } from './ac-deliveries-table-row/ac-deliveries-table-row'

export interface IDeliveriesTable {
  rows?: IOrderSummary[]
}

export const AcDeliveriesTable = ({ rows }: IDeliveriesTable) => {
  const renderTableRows = useMemo(() => {
    if (!rows?.length) return []
    return rows.map(row => <AcDeliveriesTableRow {...row} />)
  }, [rows])

  if (!rows?.length) return null

  return <AcTable rows={renderTableRows} />
}
