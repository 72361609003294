import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import {
  AcBox,
  AcColumn,
  AcContainer,
  AcDeliveryCard,
  AcPreviousDeliveries,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

export const OrdersView = observer(() => {
  const { orders } = useStore()
  useEffect(() => {
    orders.fetchDeliveries()
  }, [])

  return (
    <AcContainer>
      <AcColumn mb={6}>
        <AcTypography
          component="h1"
          removePadding>
          {LABELS.ORDERS}
        </AcTypography>
        {orders.watchNextDelivery && (
          <AcDeliveryCard
            isNextWeekDelivery
            deliveryDetails={orders.watchNextDelivery}
          />
        )}
        {orders.watchCurrentDelivery && (
          <AcDeliveryCard deliveryDetails={orders.watchCurrentDelivery} />
        )}
      </AcColumn>
      <AcTypography
        component="h2"
        color="green.main">
        {LABELS.PREVIOUS_ORDERS}
      </AcTypography>
      <AcPreviousDeliveries />
    </AcContainer>
  )
})
