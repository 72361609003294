import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'

import {
  AcButton,
  AcCheckboxGroup,
  AcFormInput,
  AcFormProvider,
  AcIcon,
  AcRow,
} from '@components'
import { ICONS, LABELS, WEEKDAYS } from '@constants'
import { useBreakpoints } from '@hooks/use-breakpoints'
import { useStore } from '@hooks/use-store'

export interface IAcLuncherDefaultAttendanceForm {
  nextStep: boolean
}

export const AcLuncherDefaultAttendanceForm = ({
  nextStep,
}: IAcLuncherDefaultAttendanceForm) => {
  const { isMobile } = useBreakpoints()
  const navigate = useNavigate()
  const { onboarding, toaster } = useStore()
  const initial = {
    attendance: [],
  }

  const getWeekdayOptions = useMemo(() => {
    const keys = Object.entries(WEEKDAYS)
    return keys.map(([key, value]) => ({
      id: key,
      value: key.toLowerCase(),
      title: value,
    }))
  }, [])

  const onSubmit = async (data: any) => {
    const success = await onboarding.patchAllergies(data)
    if (nextStep) {
      navigate(NAVIGATE_TO.ONBOARDING_COMPLETED)
    } else {
      toaster.success({ content: LABELS.SAVED_DEFAULT_ATTENDANCE })
    }
  }

  return (
    <AcFormProvider
      initial={initial}
      onSubmit={onSubmit}
      flexGrow={1}>
      <AcFormInput
        register="attendance"
        Component={
          <AcCheckboxGroup
            fullWidth
            id="attendance"
            options={getWeekdayOptions}
          />
        }
      />
      {!nextStep ? (
        <AcButton
          type="submit"
          fullWidth
          sx={{ marginTop: isMobile ? 'auto' : 2 }}>
          {LABELS.CONFIRM}
        </AcButton>
      ) : (
        <AcRow
          alignItems="center"
          justifyContent="space-between"
          marginTop={isMobile ? 'auto' : 2}>
          <AcButton
            type="button"
            onClick={() => navigate(-1)}
            variant="text"
            startIcon={<AcIcon icon={ICONS.BACK} />}>
            {LABELS.BACK}
          </AcButton>
          <AcButton
            type="submit"
            endIcon={<AcIcon icon={ICONS.NEXT} />}>
            {LABELS.NEXT}
          </AcButton>
        </AcRow>
      )}
    </AcFormProvider>
  )
}
