import type { IRadioGroupOption } from '@components'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Radio } from '@mui/material'

import { AcColumn, AcFormlabelWrapper,AcTypography } from '@components'

const CustomLabel = ({
  title,
  label,
}: {
  title: string | undefined
  label: string
}) => {
  return (
    <AcColumn gap={0}>
      {title && (
        <AcTypography
          bold
          component="span"
          data-name="ac-radio-title">
          {title}
        </AcTypography>
      )}
      <AcTypography component="span">{label}</AcTypography>
    </AcColumn>
  )
}

export const AcRadioContained = ({
  title,
  label,
  value,
}: IRadioGroupOption) => {
  return (
    <AcFormlabelWrapper
      value={value}
      control={<Radio checkedIcon={<CheckCircleIcon />} />}
      label={
        <CustomLabel
          title={title}
          label={label}
        />
      }
    />
  )
}
