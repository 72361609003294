import { AcButton, AcRow, AcTextarea } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

export const AcCancelLunchForm = () => {
  const { dialog } = useStore()

  return (
    <>
      <AcTextarea label={'Reden'} />

      <AcRow justifyContent={'space-between'}>
        <AcButton
          onClick={() => dialog.close()}
          variant="text">
          {LABELS.CANCEL}
        </AcButton>
        <AcButton>{LABELS.SUBMIT}</AcButton>
      </AcRow>
    </>
  )
}
