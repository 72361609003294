import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'

import {
  AcButton,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcIcon,
  AcRadioGroup,
  AcRow,
} from '@components'
import { ICONS, KEYS, LABELS } from '@constants'
import { useBreakpoints } from '@hooks/use-breakpoints'
import { useStore } from '@hooks/use-store'

export interface IAcLuncherPreferenceForm {
  nextStep: boolean
}

const FormInputs = ({ nextStep }: IAcLuncherPreferenceForm) => {
  const navigate = useNavigate()
  const { isMobile } = useBreakpoints()

  const options = [
    {
      id: 1,
      title: LABELS.MIX_EXTENDED,
      label: LABELS.MIX_EXPLAINER,
      value: KEYS.MIX,
    },
    {
      id: 2,
      title: LABELS.VEGETARIAN_EXTENDED,
      label: LABELS.VEGETARIAN_EXPLAINER,
      value: KEYS.VEGETARIAN,
    },
    {
      id: 3,
      title: LABELS.VEGAN_EXTENDED,
      label: LABELS.VEGAN_EXPLAINER,
      value: KEYS.VEGAN,
    },
  ]
  return (
    <>
      <AcFormInput
        register="preference"
        Component={
          <AcRadioGroup
            fullWidth
            id="luncher-preferences"
            options={options}
          />
        }
      />
      {!nextStep ? (
        <AcButton
          sx={{ marginTop: isMobile ? 'auto' : 2 }}
          type="submit"
          fullWidth>
          {LABELS.CONFIRM}
        </AcButton>
      ) : (
        <AcRow
          alignItems="center"
          justifyContent="space-between"
          marginTop={isMobile ? 'auto' : 2}>
          <AcButton
            type="button"
            onClick={() => navigate(-1)}
            variant="text"
            startIcon={<AcIcon icon={ICONS.BACK} />}>
            {LABELS.BACK}
          </AcButton>
          <AcButton
            type="submit"
            endIcon={<AcIcon icon={ICONS.NEXT} />}>
            {LABELS.NEXT}
          </AcButton>
        </AcRow>
      )}
    </>
  )
}

export const AcLuncherPreferenceForm = ({
  nextStep,
}: IAcLuncherPreferenceForm) => {
  const navigate = useNavigate()
  const { onboarding, toaster } = useStore()
  const initial = {
    preference: null,
  }

  const onSubmit = async (data: any) => {
    const success = await onboarding.patchPreferences(data)
    if (nextStep) {
      navigate(NAVIGATE_TO.ONBOARDING_ALLERGIES)
    } else {
      toaster.success({ content: LABELS.SAVED_PREFERENCES })
    }
  }

  return (
    <AcFormProvider
      initial={initial}
      onSubmit={onSubmit}
      flexGrow={1}>
      <FormInputs nextStep={nextStep} />
    </AcFormProvider>
  )
}
