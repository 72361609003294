import { forwardRef } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import { Alert, AlertProps,AlertTitle, Box } from '@mui/material'

import { AcTypography } from '@components'

export interface IAcAlert extends AlertProps {
  severity: 'success' | 'warning' | 'error'
  title?: string
}

// Alert needs a forwardRef for the AcToaster animation
// See issue: https://github.com/mui/material-ui/issues/21089
export const AcAlert = forwardRef(
  ({ severity, title, children, ...props }: IAcAlert, ref) => {
    return (
      <Box ref={ref}>
        <Alert
          severity={severity}
          iconMapping={{
            success: <CheckCircleIcon fontSize="small" />,
            warning: <WarningIcon fontSize="small" />,
            error: <ErrorIcon fontSize="small" />,
          }}
          {...props}>
          {title && <AlertTitle>{title}</AlertTitle>}
          <AcTypography
            component="span"
            size="sm">
            {children}
          </AcTypography>
        </Alert>
      </Box>
    )
  },
)
