import type { ICheckboxGroupOption } from '@components'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export const AcCheckboxDefault = ({
  label,
  value,
  checked,
}: ICheckboxGroupOption) => {
  return (
    <FormControlLabel
      value={value}
      label={label}
      sx={({ palette }) => ({
        color: palette.black.main,
        '&.Mui-checked': {
          color: palette.green.main,
        },
      })}
      control={
        <Checkbox
          checked={checked}
          color="green"
          sx={({ palette }) => ({
            color: palette.black.main,
            '&.Mui-checked': {
              color: palette.green.main,
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${palette.orange.main}`,
              outlineOffset: -10,
              borderRadius: 3,
            },
          })}
        />
      }
    />
  )
}
