import { SvgIcon } from '@mui/material'

import { CUSTOM_ICON_ELEMENTS, ICON_ELEMENTS } from '@constants'

export interface IAcIcon {
  icon: string
}

export const AcIcon = ({ icon }: IAcIcon) => {
  // @ts-ignore
  const Icon = ICON_ELEMENTS[icon]
  if (Icon) return <Icon />
  if (!Icon) {
    // @ts-ignore
    const CustomIcon = CUSTOM_ICON_ELEMENTS[icon]
    if (CustomIcon) {
      return (
        <SvgIcon>
          <CustomIcon />
        </SvgIcon>
      )
    }
  }
  return null
}
