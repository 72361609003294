import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

/**
 * Returns x amount of weeks starting from the current week.
 */
export const getWeeks = (count: number) => {
  return Array.from({ length: count }, (_, i) => dayjs().isoWeek() + i)
}
