import { FormProvider, useForm } from 'react-hook-form'

import { AcColumn } from '@components'

export interface IAcForm {
  children: React.ReactNode
  initial: object | Function
  onSubmit: (data: any) => void
  flexGrow?: number
}

export const AcFormProvider = ({
  children,
  initial,
  onSubmit,
  flexGrow,
}: IAcForm) => {
  const methods = useForm({
    defaultValues: initial,
  })

  return (
    <FormProvider {...methods}>
      <AcColumn
        gap={0}
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        flexGrow={flexGrow}>
        {children}
      </AcColumn>
    </FormProvider>
  )
}
