import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { useDateHelpers } from '@hooks/use-date-helpers'
import { Store } from '@stores'
import { IOrderDetails, IOrderSummary } from '@typings'

import mockDeliveries from '../__mock_data/deliveries.json'
import mockDouble from '../__mock_data/double-chart.json'
import mockSingle from '../__mock_data/single-chart.json'

export class OrdersStore {
  store: Store
  loading: boolean
  next_delivery: IOrderSummary | undefined
  current_delivery: IOrderSummary | undefined
  previous_deliveries: IOrderSummary[] | undefined

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.next_delivery = undefined
    this.current_delivery = undefined
    this.previous_deliveries = []
  }
  @computed
  get watchLoading(): OrdersStore['loading'] {
    return this.loading
  }

  @computed
  get watchNextDelivery(): OrdersStore['next_delivery'] {
    return this.next_delivery
  }

  @computed
  get watchCurrentDelivery(): OrdersStore['current_delivery'] {
    return this.current_delivery
  }

  @computed
  get watchpreviousDeliveries(): OrdersStore['previous_deliveries'] {
    return this.previous_deliveries
  }

  @action
  fetchMockchartSingle = () => {
    const res = mockSingle
    return res
  }

  @action
  fetchMockchartDouble = () => {
    const res = mockDouble
    return res
  }

  @action
  fetchDeliveries = () => {
    const { currentWeek } = useDateHelpers()
    const res = mockDeliveries

    const nextDelivery = (res as IOrderSummary[]).find(
      ({ week }) => week > currentWeek,
    )
    const currentDelivery = (res as IOrderSummary[]).find(
      ({ week }) => week === currentWeek,
    )

    const previousDeliveries = (res as IOrderSummary[]).filter(
      ({ week }) => week < currentWeek,
    )
    this.store.set(STORE_KEYS.ORDERS, 'next_delivery', nextDelivery)
    this.store.set(STORE_KEYS.ORDERS, 'current_delivery', currentDelivery)
    this.store.set(STORE_KEYS.ORDERS, 'previous_deliveries', previousDeliveries)

    return res
  }
}
