import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import { NAVIGATE_TO } from '@routes'

import { AcIcon } from '@components'

export const AdminNavigation = [
  {
    id: 1,
    href: NAVIGATE_TO.ADMIN_DASHBOARD,
    label: 'Dashboard',
    icon: <DashboardOutlinedIcon />,
  },
  {
    id: 2,
    href: NAVIGATE_TO.ADMIN_ORDERS,
    label: 'Bestellingen',
    icon: <ListAltRoundedIcon />,
  },
  {
    id: 3,
    href: NAVIGATE_TO.ADMIN_PROFILE,
    label: 'Lunchprofiel',
    icon: <ManageAccountsOutlinedIcon />,
  },
]

export const LuncherNavigation = [
  {
    id: 4,
    href: NAVIGATE_TO.LUNCHER_OVERVIEW,
    label: 'Aanwezigheid',
    icon: <AcIcon icon="PERSON_EDIT" />,
  },
  {
    id: 5,
    href: NAVIGATE_TO.LUNCHER_PREFERENCES,
    label: 'Voorkeuren',
    icon: <ArticleOutlinedIcon />,
  },
]
