import { IUser } from '@typings'

const USERS = '/users'
const USER = '/user'
const PROFILE = '/profile'
const AUTH = '/auth'

export const ENDPOINTS = {
  USERS: {
    SINGLE: (ID: IUser['id']) => `${USERS}/${ID}`,
  },

  USER: {
    WHO_AM_I: `${AUTH}/whoami`,
    ROLES: `${USER}/roles`,
    ALLERGIES: `${USER}/allergies`,
    PREFERENCE: `${USER}/preference`,
    DEFAULT_ATTENDANCE: `${USER}/default-attendance`,
    PROFILE: `${USER}/profile`,
  },

  AUTHENTICATION: {
    LOGIN: `${AUTH}/login`,
    REQUEST_NEW_PASSWORD: `${AUTH}/forgot-password`,
    CHANGE_PASSWORD: `${AUTH}/change-password`,
    VALIDATE_RESET: `${AUTH}/validate-reset`,
    RESET_PASSWORD: `${AUTH}/reset-password`,
  },
}
