import { Container, ContainerProps } from '@mui/material'

export interface IAcContainer extends ContainerProps {
  wide?: boolean
}

export const AcContainer = ({
  children,
  wide = false,
  ...props
}: IAcContainer) => {
  return (
    <Container
      sx={theme => ({
        maxWidth: wide ? 1084 : 804,
      })}
      {...props}>
      {children}
    </Container>
  )
}
