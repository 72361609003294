import { AppBar, Toolbar } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { IAcDefaultLayout } from '@layouts'

// @ts-ignore
import { ReactComponent as BuurtboerLogo } from '@assets/logos/buurtboer-logo-horizontal.svg'
import { AcBox, AcCustomerSettings, AcMenuItem } from '@components'
import { useBreakpoints } from '@hooks/use-breakpoints'
import { useRoleToggle } from '@hooks/use-role-toggle'

export interface IAcNavbar {
  variant: IAcDefaultLayout['variant']
}

export const AcNavbar = observer(({ variant }: IAcNavbar) => {
  const { isMobile } = useBreakpoints()
  const { navigation } = useRoleToggle()

  return (
    <AppBar color={variant === 'branded' ? 'transparent' : 'green'}>
      <AcBox
        ml={5}
        mr={5}>
        <Toolbar disableGutters>
          <BuurtboerLogo />
          {!isMobile && (
            <AcBox
              ml={6}
              sx={{ flexGrow: 1, display: 'flex' }}>
              {navigation.map(page => (
                <AcMenuItem {...page} />
              ))}
            </AcBox>
          )}
          <AcCustomerSettings />
        </Toolbar>
      </AcBox>
    </AppBar>
  )
})
