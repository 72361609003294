import { Input,styled } from '@mui/material'

export const AcStyledNumberInput = styled(Input)(({ theme }) => ({
  borderRadius: 50,
  background: theme.palette.white.main,
  color: theme.palette.green.main,
  fontSize: theme.typography.fontSize,
  paddingBlock: theme.spacing(2),
  maxHeight: 56,
  textAlign: 'center',
  '&::placeholder': {
    fontSize: theme.typography.fontSize,
  },
  '&::before, &::after': {
    display: 'none',
  },
  '.MuiSvgIcon-root': {
    cursor: 'pointer',
  },
  '.MuiInputBase-input': {
    textAlign: 'center',
    maxWidth: 24,
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
}))
