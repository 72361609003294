import Slide, { SlideProps } from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'
import { observer } from 'mobx-react-lite'

import { AcAlert } from '@components'
import { useStore } from '@hooks/use-store'

function SlideTransition(props: SlideProps) {
  return (
    <Slide
      {...props}
      direction="down"
    />
  )
}

export const AcToaster = observer(() => {
  const { toaster } = useStore()

  return (
    <Snackbar
      open={toaster.is_open}
      autoHideDuration={toaster?.timeout || 6000}
      onClose={toaster.close}
      message={toaster.content}
      TransitionComponent={SlideTransition}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <AcAlert
        severity={toaster.severity}
        title={toaster.title}>
        {toaster.content}
      </AcAlert>
    </Snackbar>
  )
})
