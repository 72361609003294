import { Box } from '@mui/material'

export interface IAcTabPanel {
  activeIndex: number
  index: number
  children: React.ReactNode
  gap?: number
}

export const AcTabPanel = ({
  activeIndex,
  index,
  children,
  gap = 6,
  ...props
}: IAcTabPanel) => {
  return (
    <Box
      role="tabpanel"
      hidden={activeIndex !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...props}>
      {activeIndex === index && <Box my={gap}>{children}</Box>}
    </Box>
  )
}
