import { Link } from 'react-router-dom'
import { NAVIGATE_TO, ROUTE_KEYS } from '@routes'

import {
  AcButton,
  AcColumn,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

export const AcRequestNewPasswordForm = () => {
  const { auth } = useStore()
  const onSubmit = async (values: any) => {
    const success = await auth.postRequestPasswordReset(values.email)
    console.log(success)
  }

  return (
    <AcFormProvider
      initial={[]}
      onSubmit={onSubmit}
      flexGrow={1}>
      <AcFormInput
        register={'email'}
        Component={<AcEmailInput label={LABELS.EMAIL_ADDRESS} />}
      />
      <AcColumn
        spacing="xl"
        flexGrow={1}>
        <AcTypography removePadding>
          <Link to={NAVIGATE_TO.LOGIN}>{LABELS.REMEMBERED_PASSWORD}</Link>
        </AcTypography>
        <AcButton
          variant="primary"
          type="submit"
          fullWidth
          sx={{ marginTop: 'auto' }}>
          {LABELS.SEND}
        </AcButton>
      </AcColumn>
    </AcFormProvider>
  )
}
