import { Divider } from '@mui/material'

export interface IAcDivider {
  light?: boolean
}

export const AcDivider = ({ light = false }) => {
  if (light) return <Divider sx={{ bgcolor: 'white.main', opacity: 0.2 }} />
  return <Divider />
}
