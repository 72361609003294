import { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { usePWAInstall } from '@hooks/use-pwa-install.hooks'

import { ROUTER } from './routes.routes'

export const AcRouter = observer(() => {
  const router = createBrowserRouter(ROUTER)
  const { saveEvent } = usePWAInstall()

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      console.log('PWA Install prompt available')
      e.preventDefault()
      saveEvent(e as any)
    })
  }, [])

  return <RouterProvider router={router} />
})
