import { useMemo } from 'react'
import { AcAnimatedFade, AcAnimatedSpring } from '@animations'

import { AcAttendanceSwiper, AcCard, AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

export const LuncherAttendanceView = () => {
  const { user } = useStore()

  const greeting = useMemo(() => {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      return LABELS.GOOD_MORNING
    } else if (curHr < 18) {
      return LABELS.GOOD_AFTERNOON
    } else {
      return LABELS.GOOD_EVENING
    }
  }, [])

  return (
    <AcColumn
      mb={8}
      sx={{ overflow: 'hidden' }}>
      <AcAnimatedFade>
        <AcColumn
          gap={0}
          textAlign={'center'}>
          <AcTypography
            component="h1"
            color="white.main"
            removePadding>
            {greeting} {user.user?.first_name}
          </AcTypography>
          <AcTypography
            color="white.main"
            size="lg">
            {LABELS.WHEN_WILL_YOU_ATTEND}
          </AcTypography>
        </AcColumn>
      </AcAnimatedFade>
      <AcAnimatedSpring>
        <AcAttendanceSwiper />
      </AcAnimatedSpring>
    </AcColumn>
  )
}
