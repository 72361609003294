import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { AcButton, AcColumn, AcDeliveriesTable, AcTabs } from '@components'
import { useStore } from '@hooks/use-store'

export const AcPreviousDeliveries = observer(() => {
  const { orders } = useStore()

  const tabs = useMemo(() => {
    return [
      {
        title: '2024',
        content: (
          <AcColumn
            alignItems="center"
            sx={{ mt: -2 }}>
            <AcDeliveriesTable rows={orders.watchpreviousDeliveries} />
            <AcButton
              variant="secondary"
              sx={{ width: 'fit-content' }}>
              Laad meer
            </AcButton>
          </AcColumn>
        ),
      },
      {
        title: '2023',
        content: (
          <AcColumn
            alignItems="center"
            sx={{ mt: -2 }}>
            <AcDeliveriesTable rows={orders.watchpreviousDeliveries} />
            <AcButton
              variant="secondary"
              sx={{ width: 'fit-content' }}>
              Laad meer
            </AcButton>
          </AcColumn>
        ),
      },
      {
        title: '2022',
        content: (
          <AcColumn
            alignItems="center"
            sx={{ mt: -2 }}>
            <AcDeliveriesTable rows={orders.watchpreviousDeliveries} />
            <AcButton
              variant="secondary"
              sx={{ width: 'fit-content' }}>
              Laad meer
            </AcButton>
          </AcColumn>
        ),
      },
      {
        title: '2021',
        content: (
          <AcColumn
            alignItems="center"
            sx={{ mt: -2 }}>
            <AcDeliveriesTable rows={orders.watchpreviousDeliveries} />
            <AcButton
              variant="secondary"
              sx={{ width: 'fit-content' }}>
              Laad meer
            </AcButton>
          </AcColumn>
        ),
      },
    ]
  }, [orders.watchpreviousDeliveries])

  return (
    <AcTabs
      small
      tabs={tabs}
      gap={4}
    />
  )
})
