import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import {
  AcButton,
  AcCard,
  AcCharts,
  AcColumn,
  AcRow,
  AcTable,
  AcTypography,
} from '@components'

export const AcOrderDetailsOverviewTab = ({
  singleChart,
}: {
  singleChart: any
}) => {
  return (
    <AcColumn>
      <AcCard>
        <AcRow justifyContent="space-between">
          <AcTypography>Aantallen</AcTypography>
          <AcButton
            startIcon={<FileDownloadOutlinedIcon />}
            variant="text">
            Download lijst met lunchers
          </AcButton>
        </AcRow>
        {singleChart && <AcCharts chartData={singleChart} />}
      </AcCard>
      <AcTable
        head={['Soep voorkeur']}
        rows={[
          <AcTable.Row>
            <AcTable.Cell>
              <AcColumn gap={1}>
                <AcTypography
                  color="green.main"
                  component="span"
                  bold>
                  Thaise mango soep
                </AcTypography>
                <AcTypography component="span">Beschrijving</AcTypography>
              </AcColumn>
            </AcTable.Cell>
          </AcTable.Row>,
          <AcTable.Row>
            <AcTable.Cell>
              <AcColumn gap={1}>
                <AcTypography
                  color="green.main"
                  component="span"
                  bold>
                  Thaise mango soep
                </AcTypography>
                <AcTypography component="span">Beschrijving</AcTypography>
              </AcColumn>
            </AcTable.Cell>
          </AcTable.Row>,
        ]}
      />
      <AcTable
        head={['Salade voorkeur']}
        rows={[
          <AcTable.Row>
            <AcTable.Cell>
              <AcColumn gap={1}>
                <AcTypography
                  color="green.main"
                  component="span"
                  bold>
                  Thaise mango soep
                </AcTypography>
                <AcTypography component="span">Beschrijving</AcTypography>
              </AcColumn>
            </AcTable.Cell>
          </AcTable.Row>,
          <AcTable.Row>
            <AcTable.Cell>
              <AcColumn gap={1}>
                <AcTypography
                  color="green.main"
                  component="span"
                  bold>
                  Thaise mango soep
                </AcTypography>
                <AcTypography component="span">Beschrijving</AcTypography>
              </AcColumn>
            </AcTable.Cell>
          </AcTable.Row>,
        ]}
      />
    </AcColumn>
  )
}
