import { EmailOutlined } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'

import { AcTextInput, IAcTextInput } from '@components'

export const AcEmailInput = ({ ...props }: IAcTextInput) => {
  return (
    <AcTextInput
      type="email"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EmailOutlined sx={{ color: 'green.main' }} />
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
      {...props}
    />
  )
}
