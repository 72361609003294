import { cloneElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import type { IAcFormInputRules } from '@typings'

import { AcBox } from '@components'

export interface IAcFormInputBase {
  register: string
  customError?: string
  rules?: IAcFormInputRules
  Component: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  lastChild?: boolean
}

export const AcFormInput = ({
  register,
  customError,
  Component,
  rules,
  lastChild,
}: IAcFormInputBase) => {
  const { control } = useFormContext()

  return (
    <AcBox mb={lastChild ? 0 : 3}>
      <Controller
        name={register}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          const error = customError || fieldState.error?.message

          return cloneElement(Component, {
            error,
            value: field.value,
            onChange: field.onChange,
          })
        }}
      />
    </AcBox>
  )
}
