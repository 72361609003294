import { useMemo, useState } from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { useTheme } from '@mui/material'
import type { Swiper as SwiperProps } from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// @ts-ignore
import extrasImage from '@assets/visuals/extras_image.jpg'
import { AcColumn, AcExtrasCard, AcRow } from '@components'
import { AcSwiperArrow } from '@components'
import { useBreakpoints } from '@hooks/use-breakpoints'

import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './swiper-overrides.module.scss'
import 'swiper/css'

export const AcExtrasSwiper = () => {
  const [swiperEl, setSwiperEl] = useState<SwiperProps | null>(null)
  const { isMobile } = useBreakpoints()

  const handleNext = () => {
    swiperEl?.slideNext()
  }

  const handlePrev = () => {
    swiperEl?.slidePrev()
  }

  const theme = useTheme()

  const cards = [
    <AcExtrasCard
      title="Kaaskoekjes"
      image={extrasImage}
      cardColor="pink"
      description="Lekker voor je maag"
      price={550}
    />,
    <AcExtrasCard
      title="Bananenbrood"
      cardColor="green"
      image={extrasImage}
      description="Brood en banaan in een"
      price={120}
    />,
    <AcExtrasCard
      title="Kaaskoekjes"
      image={extrasImage}
      cardColor="orange"
      description="Lekker voor je maag"
      price={330}
    />,
    <AcExtrasCard
      title="Bananenbrood"
      image={extrasImage}
      cardColor="yellow"
      description="Brood en banaan in een"
      price={595}
    />,
  ]

  const renderSlides = useMemo(() => {
    return cards.map((slide, i) => (
      <SwiperSlide
        style={{ width: 'auto' }}
        key={i}>
        {slide}
      </SwiperSlide>
    ))
  }, [handlePrev, handleNext, isMobile])

  return (
    <>
      <AcRow>
        <Swiper
          spaceBetween={theme.spacing(4)}
          slidesPerView="auto"
          modules={[Navigation, Pagination]}
          pagination={{
            bulletClass: 'ac-swiper-pagination__bullet',
            bulletActiveClass: 'ac-swiper-pagination__bullet--active',
            clickable: true,
            el: '.ac-swiper-pagination',
          }}
          onSwiper={setSwiperEl}>
          {renderSlides}
        </Swiper>
      </AcRow>
      <AcRow justifyContent="space-between">
        <AcRow gap={2}>
          <AcSwiperArrow onClick={handlePrev}>
            <ArrowBackOutlinedIcon />
          </AcSwiperArrow>
          <AcSwiperArrow onClick={handleNext}>
            <ArrowForwardOutlinedIcon />
          </AcSwiperArrow>
        </AcRow>
        <AcRow>
          <AcColumn className="ac-swiper-pagination"></AcColumn>
        </AcRow>
      </AcRow>
    </>
  )
}
