import { useMemo } from 'react'
import { ArrowForward } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'

import { AcTable, AcTag, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useDateHelpers } from '@hooks/use-date-helpers'
import { IOrderSummary } from '@typings'

export const AcDeliveriesTableRow = (row: IOrderSummary) => {
  const { weekStartDate, weekEndDateFriday } = useDateHelpers(
    row.start_of_week,
    { dateOptions: { day: '2-digit', month: 'long' } },
  )

  const tagColor = useMemo(() => {
    switch (row.status) {
      case 'CONFIRMED':
      case 'FULFILLED':
      default:
        return 'green'

      case 'PENDING':
        return 'salmon'

      case 'CANCELLED':
        return 'pink'
    }
  }, [row.status])

  const renderIcon = useMemo(() => {
    switch (row.status) {
      case 'CONFIRMED':
      case 'FULFILLED':
      default:
        return <CheckCircleIcon sx={{ color: 'green.main' }} />

      case 'PENDING':
      case 'CANCELLED':
        return <IndeterminateCheckBoxIcon sx={{ color: 'pink.main' }} />
    }
  }, [row.status])

  const renderTitle = useMemo(() => {
    return `Week ${row.week} - ${weekStartDate} t/m ${weekEndDateFriday}`
  }, [row.week])

  return (
    <AcTable.Row>
      <AcTable.Cell>{renderIcon}</AcTable.Cell>
      <AcTable.Cell>
        <AcTypography
          component="span"
          bold
          color="green.main">
          {renderTitle}
        </AcTypography>
      </AcTable.Cell>
      <AcTable.Cell>
        <AcTag
          color={tagColor}
          label={LABELS[row.status as keyof typeof LABELS]}
        />
      </AcTable.Cell>
      <AcTable.Cell align="right">
        <ArrowForward sx={{ color: 'green.main' }} />
      </AcTable.Cell>
    </AcTable.Row>
  )
}
