export const LABELS = {
  LUNCHER: 'Luncher',
  OFFICE_MANAGER: 'Office manager',
  ADMIN: 'Administratie',
  SWITCH_ROLE: 'Verwissel van rol',
  USER_ROLES: 'Rollen',
  GOOD_MORNING: 'Goedemorgen',
  GOOD_AFTERNOON: 'Goedemiddag',
  GOOD_EVENING: 'Goedenavond',
  WHEN_WILL_YOU_ATTEND: 'Wanneer lunch je mee?',
  WHEN_WILL_YOU_ATTEND_SUBTITLE:
    'Geef door op welke dagen je meestal verwacht mee te lunchen.',
  DO_YOU_HAVE_ALLERGIES: 'Heb je allergieën?',
  DO_YOU_HAVE_ALLERGIES_SUBTITLE:
    'Zijn er nog andere dingen waar we rekening mee moeten houden?',
  WHAT_ARE_YOUR_PREFERENCES: 'Wat lunch jij het liefst?',
  PREFERENCES_MAY_CHANGE: 'Dit kan altijd nog wijzigen.',
  wHAT_ARE_YOUR_PREFERENCES_SUBTITLE:
    'Laat ons weten wat jij tijdens je luncht wilt eten.',
  PREFERENCES: 'Voorkeuren',
  PRFERENCES_EXTENDED: 'Voorkeuren (mix, vega, vegan)',
  CANCEL: 'Annuleren',
  SEND: 'Verstuur',
  SUBMIT: 'Verzenden',
  NEXT: 'Volgende',
  BACK: 'Terug',
  MIX: 'Mix',
  MIX_EXTENDED: 'Mix van vlees, vis en groente',
  MIX_EXPLAINER: 'Een mix van vlees, vis en vegetarische producten',
  VEGETARIAN: 'Vegetarisch',
  VEGETARIAN_EXTENDED: 'Alleen vegetarisch',
  VEGETARIAN_EXPLAINER:
    'Veel vegetarische producten afgewisseld met zuivel en vleesvervangers',
  VEGAN: 'Veganistisch',
  VEGAN_EXTENDED: 'Alleen veganistisch',
  VEGAN_EXPLAINER: 'Uitsluitend plantaardige producten en vleesvervangers',
  ALLERGIES: 'Allergieën',
  ALLERGIES_NONE: 'Geen allergie',
  ALLERGIES_GLUTEN: 'Glutenallergie',
  ALLERGIES_NUTS: 'Notenallergie',
  ALLERGIES_PEANUTS: 'Pinda-allergie',
  ALLERGIES_FISH: 'Visallergie',
  ALLERGIS_LACTOSE: 'Lactosevrij',
  SAVED_ALLERGIES: 'Je allergieën zijn opgeslagen',
  SAVED_PREFERENCES: 'Je allergieën zijn opgeslagen',
  SAVED_ATTENDANCE: 'Je aanwezigheid is bevestigd',
  SAVED_DEFAULT_ATTENDANCE: 'Je standaard lunchdagen zijn opgeslagen',
  OR: 'Of',
  ALLERGIES_NOT_LISTED: 'Andere allergie?',
  CONTACT_OFFICE_MANAGER:
    'Neem contact met jouw office manager op om dit op te nemen.',
  CONFIRM: 'Bevestigen',
  YOUR_LOGIN_DETAILS: 'Jouw inloggegevens',
  YOUR_LOGIN_DETAILS_TEXT: 'Log hier in met je e-mailadres en wachtwoord.',
  CREATE_ACCOUNT: 'Maak een account',
  WELCOME: 'Welkom!',
  REGISTER: 'Registreren',
  EMAIL_ADDRESS: 'E-mailadres',
  PASSWORD: 'Wachtwoord',
  CONFIRM_PASSWORD: 'Bevestig wachtwoord',
  SET_PASSWORD: 'Stel je wachtwoord in',
  CHANGE_PASSWORD: 'Wachtwoord wijzigen',
  CHANGE_PASSWORD_BUTTON: 'Wijzig wachtwoord',
  CHANGE_PASSWORD_TEXT: 'Stel een nieuw wachtwoord in.',
  FORGOT_PASSWORD: 'Wachtwoord vergeten?',
  FORGOT_PASSWORD_TEXT:
    'We sturen een e-mail met link naar jouw e-mailadres. Hiermee kun je je wachtwoord opnieuw instellen.',
  REMEMBERED_PASSWORD: 'Ik weet mijn wachtwoord',
  BACK_TO_LOGIN: 'Terug naar inloggen',
  NO_ACCESS: 'Helaas geen toegang',
  NO_ACCESS_TEXT:
    'Je hebt helaas geen toegang meer tot Mijn Buurtboer. Neem contact op met jouw office manager.',
  CURRENT_PASSWORD: 'Huidig wachtwoord',
  NEW_PASSWORD: 'Nieuw wachtwoord',
  CONFIRM_NEW_PASSWORD: 'Bevestig nieuw wachtwoord',
  DEFAULT_ATTENDANCE: 'Standaard lunchdagen',
  YOUR_REGISTRATION_DETAILS_SUBTITLE:
    'Vertel ons je naam en het emailadres waarop je bereikbaar bent.',
  NAME: 'Naam',
  CONFIRM_ATTENDANCE_DEADLINE: 'Bevestigen kan tot',
  WEEK: 'Week',
  SEND_REMINDER: 'Stuur herinnering',
  WEEK_MENU: 'Weekmenu',
  START_ORDERING: 'Start met bestellen',
  ORDER_WEEK: 'Bestelling week',
  ORDERS: 'Bestellingen',
  PREVIOUS_ORDERS: 'Eerdere bestellingen',
  EXTRAS: "Extra's",
  EXTRAS_TEXT: 'Lekker extra bij de lunch.',
  LUNCH_PREFERENCES: 'Lunchvoorkeuren',
  FINALIZE_BY: 'Rond af voor',
  AMOUNT_REGISTERED: 'Aantal aangemeld',
  SHARE_MESSAGE: 'Deel bericht',
  COPIED_TO_CLIPBOARD: 'Succesvol gekopieerd naar je klembord',
  MESSAGE_TO_ALL_LUNCHERS: 'Jouw bericht aan alle lunchers',
  AMOUNTS: 'Aantallen',
  LOGIN_SUCCESSFUL: 'Succesvol ingelogd',
  LOGIN_FAILED: 'E-mailadres of wachtwoord is onjuist',
  CONFIRMED: 'Bevestigd',
  FULFILLED: 'Geleverd',
  CANCELLED: 'Geannuleerd',
  PENDING: 'In behandeling',
  RATE_PRODUCT_MORE: 'Super leker',
  RATE_PRODUCT_MORE_EXT: 'Ontvang vaker dan gemiddeld',
  RATE_PRODUCT_NICE: 'Lekker',
  RATE_PRODUCT_NICE_EXT: 'Ontvang gemiddeld aantal keer',
  RATE_PRODUCT_LESS: 'Niet te vaak',
  RATE_PRODUCT_LESS_EXT: 'Ontvang minder vaak dan gemiddeld',
  RATE_PRODUCT_REMOVE: 'Uitsluiten',
  RATE_PRODUCT_REMOVE_EXT: 'Geen leveringen meer van dit product',
}
