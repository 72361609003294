import { ThemeOptions } from '@mui/material'

import { THEME_COLORS } from '@constants'

export const PALETTE_LIGHT: ThemeOptions['palette'] = {
  mode: 'light',
  primary: {
    main: THEME_COLORS.BRAND_GREEN,
    light: THEME_COLORS.BRAND_LIGHT_GREEN,
    contrastText: THEME_COLORS.NEUTRAL_WHITE,
  },
  secondary: {
    main: THEME_COLORS.BRAND_YELLOW,
    contrastText: THEME_COLORS.BRAND_GREEN,
  },
  background: {
    default: THEME_COLORS.NEUTRAL_WHITE,
  },
  yellow: {
    main: THEME_COLORS.BRAND_YELLOW,
    contrastText: THEME_COLORS.BRAND_GREEN,
  },
  beige: {
    main: THEME_COLORS.BRAND_BEIGE,
    contrastText: THEME_COLORS.BRAND_GREEN,
  },
  orange: {
    main: THEME_COLORS.BRAND_ORANGE,
    contrastText: THEME_COLORS.NEUTRAL_WHITE,
  },
  pink: {
    main: THEME_COLORS.BRAND_PINK,
    contrastText: THEME_COLORS.BRAND_GREEN,
  },
  green: {
    main: THEME_COLORS.BRAND_GREEN,
    light: THEME_COLORS.BRAND_LIGHT_GREEN,
    contrastText: THEME_COLORS.NEUTRAL_WHITE,
  },
  'light-green': {
    main: THEME_COLORS.BRAND_LIGHT_GREEN,
    contrastText: THEME_COLORS.NEUTRAL_GREY_DARK,
  },
  white: {
    main: THEME_COLORS.NEUTRAL_WHITE,
    contrastText: THEME_COLORS.NEUTRAL_BLACK,
  },
  'grey-10': {
    main: THEME_COLORS.NEUTRAL_GREY_10,
    contrastText: THEME_COLORS.NEUTRAL_GREY_DARK,
  },
  'grey-20': {
    main: THEME_COLORS.NEUTRAL_GREY_20,
    contrastText: THEME_COLORS.NEUTRAL_GREY_DARK,
  },
  'grey-30': {
    main: THEME_COLORS.NEUTRAL_GREY_30,
    contrastText: THEME_COLORS.NEUTRAL_GREY_DARK,
  },
  black: {
    main: THEME_COLORS.NEUTRAL_BLACK,
    contrastText: THEME_COLORS.NEUTRAL_WHITE,
  },
  success: {
    main: THEME_COLORS.ALERT_BRAND_GREEN,
    contrastText: THEME_COLORS.NEUTRAL_WHITE,
  },
  warning: {
    main: THEME_COLORS.ALERT_BRAND_YELLOW,
    contrastText: THEME_COLORS.BRAND_GREEN,
  },
  error: {
    main: THEME_COLORS.ALERT_BRAND_ORANGE,
    contrastText: THEME_COLORS.NEUTRAL_WHITE,
  },
}
