import { ThemeOptions } from '@mui/material'

import { BREAKPOINTS as BREAKPOINTS_CONST } from '@constants'

export const BREAKPOINTS: ThemeOptions['breakpoints'] = {
  keys: ['mobile', 'tablet', 'desktop'],
  values: {
    mobile: BREAKPOINTS_CONST.MOBILE,
    tablet: BREAKPOINTS_CONST.TABLET,
    desktop: BREAKPOINTS_CONST.DESKTOP,
  },
  unit: 'px',
}
