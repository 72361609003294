import { motion } from 'framer-motion'

export const AcOnboardingSuccessSVG = () => {
  const drawCircle = {
    initial: { y: 25, opacity: 0, fill: '#E86844' },
    animate: {
      y: 0,
      opacity: 1,
      fill: '#E86844',
      transition: { duration: 0.5 },
    },
  }

  const spring = {
    type: 'spring',
    damping: 5,
    stiffness: 100,
    delay: 0.25,
  }

  return (
    <motion.svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial="hidden"
      animate="visible">
      <motion.circle
        cx="55"
        cy="55"
        r="55"
        variants={drawCircle}
        initial="initial"
        animate="animate"
      />
      <motion.path
        fill="#285847"
        d="M47.0511 64.688L36.6183 54.2552L33.1406 57.7328L47.0511 71.6433L76.8594 41.8351L73.3818 38.3574L47.0511 64.688Z"
        initial={{ opacity: 0, scale: 0.25 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={spring}
      />
    </motion.svg>
  )
}
