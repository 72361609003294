import { LABELS } from './labels.constants'

export const TOASTERS = {
  COPIED_TO_CLIPBOARD: {
    content: LABELS.COPIED_TO_CLIPBOARD,
    timeout: 3000,
  },
  LOGIN_SUCCESSFUL: {
    content: LABELS.YOUR_LOGIN_DETAILS,
  },
  LOGIN_FAILED: {
    content: LABELS.LOGIN_FAILED,
  },
  SHARE_SUCCESSFUL: {
    content: 'Herinnering succesvol gedeeld',
  },
}
