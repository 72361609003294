import React from 'react'
import TableRow, { TableRowProps } from '@mui/material/TableRow'

import { AcIcon, AcIconButton } from '@components'
import { ICONS } from '@constants'

export interface IAcTableRow extends TableRowProps {
  children: React.ReactNode
  onClick?: () => void
  icon?: React.ReactNode
}

export const AcTableRow = ({
  children,
  onClick,
  icon,
  ...props
}: IAcTableRow) => {
  return (
    <TableRow
      onClick={onClick}
      hover
      sx={{
        position: 'relative',
        cursor: onClick ? 'pointer' : 'initial',
      }}
      {...props}>
      {children}
      {onClick && (
        <AcIconButton
          color="green"
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
          {icon || <AcIcon icon={ICONS.NEXT} />}
        </AcIconButton>
      )}
    </TableRow>
  )
}
