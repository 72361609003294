import { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, Tooltip } from '@mui/material'

import {
  AcAvatar,
  AcBox,
  AcButton,
  AcCustomerSettingsDropdown,
  AcCustomerSettingsMenu,
  AcCustomerSettingsModal,
  AcIconButton,
  AcTypography,
} from '@components'
import { useBreakpoints } from '@hooks/use-breakpoints'
import { useStore } from '@hooks/use-store'

export const AcCustomerSettings = () => {
  const { user } = useStore()
  const { isMobile, isDesktop } = useBreakpoints()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const handleOpenAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchor(null)
  }

  const MenuRootEl = isMobile
    ? AcCustomerSettingsModal
    : AcCustomerSettingsDropdown

  return (
    <AcBox
      component={isMobile ? 'div' : 'button'}
      onClick={handleOpenAction}
      display="flex"
      alignItems="center"
      flexGrow={0}
      gap={4}>
      {isMobile ? (
        <AcIconButton onClick={handleOpenAction}>
          <AcAvatar
            alt={user.user?.first_name}
            src="/static/images/avatar/2.jpg"
          />
        </AcIconButton>
      ) : (
        <Tooltip title="Open settings">
          <IconButton
            tabIndex={-1}
            sx={{ padding: 0 }}>
            <AcAvatar
              alt={user.user?.first_name}
              src="/static/images/avatar/2.jpg"
            />
          </IconButton>
        </Tooltip>
      )}
      <MenuRootEl
        open={anchor}
        handleClose={handleCloseUserMenu}>
        <AcCustomerSettingsMenu handleClose={handleCloseUserMenu} />
      </MenuRootEl>
      {isDesktop && (
        <AcBox>
          <AcTypography
            size="sm"
            bold
            component="span">
            {user.user?.email}
          </AcTypography>
          <AcTypography
            size="sm"
            component="span">
            {user.user?.customer?.name}
          </AcTypography>
        </AcBox>
      )}
      {isDesktop && <ExpandMoreIcon />}
    </AcBox>
  )
}
