import { AcAnimatedFade } from '@animations'

import { AcBox, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcRegistrationForm } from '@partials'

export const OnboardingRegisterView = () => {
  return (
    <AcAnimatedFade
      enter={{ x: 20, y: 0 }}
      exit={{ x: -20, y: 0 }}
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <AcBox pb={5}>
        <AcTypography
          color="green.main"
          component="h2"
          padding="sm">
          {LABELS.YOUR_LOGIN_DETAILS}
        </AcTypography>
        <AcTypography removePadding>
          {LABELS.YOUR_REGISTRATION_DETAILS_SUBTITLE}
        </AcTypography>
      </AcBox>
      <AcRegistrationForm />
    </AcAnimatedFade>
  )
}
