import { motion } from 'framer-motion'

export interface IAcAnimatedSpring {
  children: React.ReactNode
  damping?: number
  stiffness?: number
  delay?: number
}

export const AcAnimatedSpring = ({
  children,
  damping = 10,
  stiffness = 100,
  delay = 0,
}: IAcAnimatedSpring) => {
  const spring = {
    type: 'spring',
    damping,
    stiffness,
    delay,
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={spring}
      className="relative">
      {children}
    </motion.div>
  )
}
