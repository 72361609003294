import { useStore } from './use-store'

export const usePWAInstall = () => {
  const { user } = useStore()

  const checkForInstalledApps = async () => {
    if ('navigator' in window && (navigator as any)?.getInstalledRelatedApps) {
      const apps = await (navigator as any)?.getInstalledRelatedApps()
      console.log(apps)
    }
  }

  return {
    checkForInstalledApps,
    attemptPWAInstall: user.attemptPWAInstall,
    saveEvent: user.saveDeferredPWAEvent,
  }
}
