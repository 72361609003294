import {
  AcButton,
  AcCard,
  AcFormInput,
  AcFormProvider,
  AcIcon,
  AcRadioGroup,
  AcRow,
  AcTypography,
} from '@components'
import { ICONS, KEYS, LABELS } from '@constants'
import { useBreakpoints } from '@hooks/use-breakpoints'
import { useStore } from '@hooks/use-store'

const FormInputs = () => {
  const { dialog } = useStore()
  const { isMobile } = useBreakpoints()

  const options = [
    {
      id: 1,
      title: LABELS.RATE_PRODUCT_MORE,
      label: LABELS.RATE_PRODUCT_MORE_EXT,
      value: KEYS.RATE_PRODUCT_MORE,
    },
    {
      id: 2,
      title: LABELS.RATE_PRODUCT_NICE,
      label: LABELS.RATE_PRODUCT_NICE_EXT,
      value: KEYS.RATE_PRODUCT_NICE,
    },
    {
      id: 3,
      title: LABELS.RATE_PRODUCT_LESS,
      label: LABELS.RATE_PRODUCT_LESS_EXT,
      value: KEYS.RATE_PRODUCT_LESS,
    },
    {
      id: 4,
      title: LABELS.RATE_PRODUCT_REMOVE,
      label: LABELS.RATE_PRODUCT_REMOVE_EXT,
      value: KEYS.RATE_PRODUCT_REMOVE,
    },
  ]
  return (
    <>
      <AcFormInput
        register="preference"
        Component={
          <AcRadioGroup
            fullWidth
            id="luncher-preferences"
            options={options}
          />
        }
      />
      <AcRow
        alignItems="center"
        justifyContent="space-between"
        marginTop={isMobile ? 'auto' : 2}>
        <AcButton
          type="button"
          onClick={dialog.close}
          variant="text">
          {LABELS.CANCEL}
        </AcButton>
        <AcButton type="submit">{LABELS.CONFIRM}</AcButton>
      </AcRow>
    </>
  )
}

export const AcRateProductForm = () => {
  const { toaster } = useStore()
  const initial = {
    preference: null,
  }

  const onSubmit = async (data: any) => {
    // todo - submit endpoint!
    toaster.success({ content: 'Todo: submit' })
  }

  return (
    <AcFormProvider
      initial={initial}
      onSubmit={onSubmit}
      flexGrow={1}>
      <AcCard
        cardColor="beige"
        sx={{ marginBottom: 5 }}>
        <AcTypography
          component="span"
          bold>
          Chocolade paaseieren (12 stuks)
        </AcTypography>
      </AcCard>
      <FormInputs />
    </AcFormProvider>
  )
}
