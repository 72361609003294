// Echarts handbook:
import { useMemo } from 'react'
import { useTheme } from '@mui/material'
import { BarChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import { AcBox } from '@components'
import { IAcChartData } from '@components'
import { THEME_COLORS } from '@constants'
import { useChartStyling } from '@hooks/use-chart-styling.hooks'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
])

// The usage of ReactEChartsCore are same with above.
export const AcChartBarSingle = (chartData: IAcChartData) => {
  const theme = useTheme()
  const {
    barStyles,
    topLabelStyles,
    tooltipStyles,
    XaxisLabelStyles,
    YaxisLabelStyles,
  } = useChartStyling()

  const createUnicorn = (i: number) => {
    if (i === 0) return theme.palette.green.main
    if (i === 1) return theme.palette.orange.main
    if (i === 2) return theme.palette.yellow.main
    if (i === 3) return theme.palette.pink.main
    return theme.palette.green.main
  }

  const renderDatasets = useMemo(() => {
    if (!chartData?.datasets?.length) return []
    return chartData.datasets.map(set => ({
      type: 'bar',
      name: set.datasetName,
      data: set.data.map((value, i) => ({
        value,
        itemStyle: {
          color: createUnicorn(i),
        },
      })),
      barCategoryGap: theme.spacing(4),
      label: topLabelStyles,
      itemStyle: barStyles,
    }))
  }, [chartData])

  const renderChart = useMemo(() => {
    const chartOptions = {
      series: renderDatasets,
      grid: { top: 8, right: 0, bottom: 24, left: 0 },
      xAxis: {
        type: 'category',
        data: chartData.categories.map(i => i),
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: XaxisLabelStyles,
      },
      yAxis: {
        show: false,
        type: 'value',
        axisLabel: YaxisLabelStyles,
      },
      tooltip: {
        trigger: 'axis',
        ...tooltipStyles,
      },
    }

    return (
      <AcBox
        width="100%"
        height={200}
        style={
          {
            '--ac-charts-font-family': theme.typography.fontFamily,
          } as React.CSSProperties
        }>
        <ReactEChartsCore
          style={{
            height: 200,
            width: '100%',
          }}
          echarts={echarts}
          option={chartOptions}
        />
      </AcBox>
    )
  }, [chartData])

  return renderChart
}
