export const THEME_COLORS = {
  BRAND_YELLOW: '#FECB44',
  BRAND_BEIGE: '#FCFAEE',
  BRAND_ORANGE: '#E86844',
  BRAND_PINK: '#F3C5C2',
  BRAND_GREEN: '#285847',
  BRAND_GREEN_RGB: '40, 88, 71',
  BRAND_LIGHT_GREEN: '#82AD99',
  NEUTRAL_WHITE: '#FFF',
  NEUTRAL_GREY_10: '#F5F9F7',
  NEUTRAL_GREY_20: '#D9E6E0',
  NEUTRAL_GREY_30: '#C1D6CC',
  NEUTRAL_GREY_DARK: '#525252',
  NEUTRAL_BLACK: '#10281F',
  ALERT_BRAND_GREEN: '#285847',
  ALERT_BRAND_YELLOW: '#FECB44',
  ALERT_BRAND_ORANGE: '#E86844',
}
