import type { Transition, Variants } from 'framer-motion'
import { motion } from 'framer-motion'

export interface IAcAnimationDirections {
  x: number
  y: number
}

export interface IAcAnimatedFade {
  children: React.ReactNode
  enter?: IAcAnimationDirections
  exit?: IAcAnimationDirections
  delay?: number
  style?: object
}

export const AcAnimatedFade = ({
  children,
  enter = { x: 0, y: 20 },
  exit = { x: 0, y: 20 },
  delay = 0,
  style,
}: IAcAnimatedFade) => {
  const variants: Variants = {
    hidden: { opacity: 0, ...enter },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, ...exit },
  }

  const transition: Transition = {
    duration: 0.25,
    type: 'easeInOut',
    delay,
  }

  return (
    <motion.div
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={variants}
      transition={transition}
      style={style}>
      {children}
    </motion.div>
  )
}
