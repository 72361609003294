import { action, computed, makeAutoObservable } from 'mobx'

import { LABELS } from '@constants'
import { Store } from '@stores'
import { IAllergy, IPreferenceEnum, IWeekDay } from '@typings'

export class OnboardingStore {
  store: Store
  loading: boolean
  allergyOptions: IAllergy[]
  renderInstallPrompt: boolean
  currentStep: number
  onboardingCompleted: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.renderInstallPrompt = false
    this.allergyOptions = []
    this.currentStep = 0
    this.onboardingCompleted = false
  }

  @computed
  get watchCurrentStep(): number {
    return this.currentStep
  }

  @computed
  get completed(): boolean {
    return this.onboardingCompleted
  }

  @action
  fetchAllergies = async () => {
    const allergies: IAllergy[] = [
      {
        id: 1,
        name: LABELS.ALLERGIES_GLUTEN,
      },
      {
        id: 2,
        name: LABELS.ALLERGIES_NUTS,
      },
      {
        id: 3,
        name: LABELS.ALLERGIES_PEANUTS,
      },
    ]
    return allergies
  }

  @action
  patchPreferences = (payload: IPreferenceEnum[]) => {
    console.log(payload)
  }

  @action
  patchAllergies = (payload: IAllergy['id'][]) => {
    console.log(payload)
  }

  @action
  patchAttendance = (payload: IWeekDay[]) => {
    console.log(payload)
  }

  @action
  patchIntakeCompleted = (payload: boolean) => {
    // Todo: API to patch intake completed
  }
}
