import { useEffect, useMemo, useState } from 'react'
import { FormControl, FormGroup, FormLabel } from '@mui/material'

import { AcCheckboxContained, AcCheckboxDefault } from '@components'

export interface ICheckboxGroupOption {
  title?: string
  label?: string
  value: string | number
  checked?: boolean
}

export interface ICheckboxGroup {
  id: string
  label?: string
  options: ICheckboxGroupOption[]
  variant?: 'default' | 'contained'
  fullWidth?: boolean
  value?: any[]
  onChange?: (val: any[]) => void
}

export const AcCheckboxGroup = ({
  id,
  label,
  options,
  variant = 'contained',
  value = [],
  onChange,
  fullWidth,
}: ICheckboxGroup) => {
  const handleChange = ({ target }: any) => {
    const checked = target.checked
    const newVal = target.value
    if (onChange) {
      if (checked) {
        onChange([...value, newVal])
      } else {
        onChange(value.filter(item => item !== newVal))
      }
    }
  }

  const CheckboxEl =
    variant === 'contained' ? AcCheckboxContained : AcCheckboxDefault

  const renderOptions = useMemo(() => {
    return options.map(option => (
      <CheckboxEl
        {...option}
        checked={value.includes(option.value)}
      />
    ))
  }, [options, value])

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <FormLabel
          color="black"
          id={id}>
          {label}
        </FormLabel>
      )}
      <FormGroup
        onChange={handleChange}
        aria-labelledby={id}>
        {renderOptions}
      </FormGroup>
    </FormControl>
  )
}
