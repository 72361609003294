import { useNavigate } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'

import { AcButton, IAcButton } from '@components'
import { LABELS } from '@constants'
export interface IAcBackButton {
  variant?: IAcButton['variant']
  withIcon?: boolean
}

export const AcBackButton = ({
  variant = 'primary',
  withIcon = false,
}: IAcBackButton) => {
  const navigate = useNavigate()

  return (
    <AcButton
      onClick={() => navigate(-1)}
      variant={variant}
      startIcon={withIcon && <ArrowBackOutlinedIcon />}>
      {LABELS.BACK}
    </AcButton>
  )
}
