import { useMemo } from 'react'

import { LABELS } from '@constants'

export const useGreeting = () => {
  return useMemo(() => {
    const currentHour = new Date().getHours()

    if (currentHour < 12) {
      return LABELS.GOOD_MORNING
    }

    if (currentHour < 18) {
      return LABELS.GOOD_AFTERNOON
    }

    return LABELS.GOOD_EVENING
  }, [])
}
