import {
  AcBackButton,
  AcColumn,
  AcContainer,
  AcIcon,
  AcTable,
  AcTypography,
} from '@components'
import { ICONS } from '@constants'
import { useStore } from '@hooks/use-store'
import { AcRateProductForm } from '@partials'

export const OrdersDetailDateView = () => {
  const { dialog } = useStore()

  const openDialog = () => {
    dialog.open({
      title: 'Beoordeel je product',
      content: <AcRateProductForm />,
      maxWidth: 448,
    })
  }

  return (
    <AcContainer>
      <AcBackButton
        variant="text"
        withIcon
      />
      <AcColumn
        mt={4}
        spacing="xl">
        <AcTypography
          component="h1"
          removePadding>
          Maandag 17 Juni
        </AcTypography>
        <AcTable
          head={['Spreads (3)']}
          rows={[
            <AcTable.Row
              onClick={openDialog}
              icon={<AcIcon icon={ICONS.DAY_EDIT} />}>
              <AcTable.Cell>
                <AcTypography component="span">Beschrijving</AcTypography>
              </AcTable.Cell>
            </AcTable.Row>,
            <AcTable.Row
              onClick={openDialog}
              icon={<AcIcon icon={ICONS.DAY_EDIT} />}>
              <AcTable.Cell>
                <AcTypography component="span">Beschrijving</AcTypography>
              </AcTable.Cell>
            </AcTable.Row>,
          ]}
        />
      </AcColumn>
    </AcContainer>
  )
}
