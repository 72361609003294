import { AcBox, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcResetPasswordForm } from '@partials'

export const ResetPasswordView = () => {
  return (
    <>
      <AcBox pb={5}>
        <AcTypography
          color="green.main"
          component="h1"
          variant="h2"
          padding="sm">
          {LABELS.CHANGE_PASSWORD}
        </AcTypography>
        <AcTypography removePadding>{LABELS.CHANGE_PASSWORD_TEXT}</AcTypography>
      </AcBox>
      <AcResetPasswordForm />
    </>
  )
}
