import { AcBox, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcLoginForm } from '@partials'

export const LoginView = () => {
  return (
    <>
      <AcBox pb={5}>
        <AcTypography
          color="green.main"
          component="h1"
          variant="h2"
          padding="sm">
          {LABELS.YOUR_LOGIN_DETAILS}
        </AcTypography>
        <AcTypography removePadding>
          {LABELS.YOUR_LOGIN_DETAILS_TEXT}
        </AcTypography>
      </AcBox>
      <AcLoginForm />
    </>
  )
}
