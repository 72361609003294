import { useMemo } from 'react'

import {
  AcButton,
  AcCheckboxGroup,
  AcFormInput,
  AcFormProvider,
  AcTypography,
} from '@components'
import { LABELS, WEEKDAYS } from '@constants'
import { useDateHelpers } from '@hooks/use-date-helpers'
import { useStore } from '@hooks/use-store'

export interface IAcLuncherWeeklyAttendanceForm {
  week: number
  year: number
  deadline: string
}

export const AcLuncherWeeklyAttendanceForm = ({
  week,
  year,
  deadline,
}: IAcLuncherWeeklyAttendanceForm) => {
  const { onboarding, toaster } = useStore()
  const initial = {
    attendance: [],
  }

  const getWeekdayOptions = useMemo(() => {
    const keys = Object.entries(WEEKDAYS)
    return keys.map(([key, value]) => ({
      id: key,
      value: key.toLowerCase(),
      title: value,
    }))
  }, [])

  const onSubmit = async (data: any) => {
    const success = await onboarding.patchAllergies(data)
    if (true) {
      toaster.warning({
        content: LABELS.SAVED_ATTENDANCE,
        timeout: 2500,
      })
    }
  }

  const renderDeadline = useMemo(() => {
    if (!deadline) return null
    const { prettyDate } = useDateHelpers(deadline, {
      dateOptions: {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
      },
    })
    return prettyDate
  }, [deadline])

  return (
    <AcFormProvider
      initial={initial}
      onSubmit={onSubmit}>
      <AcFormInput
        register="attendance"
        Component={
          <AcCheckboxGroup
            fullWidth
            id="attendance"
            options={getWeekdayOptions}
          />
        }
      />
      {renderDeadline && (
        <AcTypography
          size="sm"
          textAlign="center"
          pt={2}>
          {LABELS.CONFIRM_ATTENDANCE_DEADLINE} {renderDeadline}.
        </AcTypography>
      )}
      <AcButton
        fullWidth
        type="submit">
        {LABELS.CONFIRM}
      </AcButton>
    </AcFormProvider>
  )
}
