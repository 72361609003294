import { AcDialog, AcToaster } from '@components'

export const AcNotifications = () => {
  return (
    <>
      <AcToaster />
      <AcDialog />
    </>
  )
}
