import { AcAnimatedFade } from '@animations'

import { AcBox, AcTypography } from '@components'
import { AcLuncherDefaultAttendanceForm } from '@partials'

export const OnboardingAttendanceView = () => {
  return (
    <AcAnimatedFade
      enter={{ x: 20, y: 0 }}
      exit={{ x: -20, y: 0 }}
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <AcBox pb={5}>
        <AcTypography
          color="green.main"
          component="h2"
          padding="sm">
          Welke dagen lunch je mee?
        </AcTypography>
        <AcTypography removePadding>
          Geef door op welke dagen je meestal verwacht mee te lunchen.
        </AcTypography>
      </AcBox>
      <AcLuncherDefaultAttendanceForm nextStep={true} />
    </AcAnimatedFade>
  )
}
