import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { AcButton, AcRow, AcTextarea } from '@components'
import { LABELS } from '@constants'
import { copyToClipboard } from '@helpers/copy-to-clipboard'
import { useStore } from '@hooks/use-store'

import { TOASTERS } from '../../../constants/toasters.constants'

export const AcLunchReminderForm = observer(() => {
  const { dialog, toaster } = useStore()

  const [message, setMessage] = useState('')

  const handleShare = async () => {
    // TODO: Set URL
    const shareUrl = `${window.location.origin}`
    if (navigator?.share) {
      await navigator
        .share({
          title: 'Vul je aanwezigheid in',
          text: message,
          url: shareUrl,
        })
        .then(() => toaster.success(TOASTERS.SHARE_SUCCESSFUL))
        .catch(error => console.log(error))
    } else {
      await navigator.clipboard
        .writeText(shareUrl)
        .then(() => toaster.success(TOASTERS.COPIED_TO_CLIPBOARD))
    }
  }

  return (
    <>
      <AcTextarea
        label={LABELS.MESSAGE_TO_ALL_LUNCHERS}
        value={message}
        onChange={event => setMessage(event.target.value)}
      />

      <AcRow
        justifyContent="space-between"
        alignItems="center">
        <AcButton
          onClick={() => dialog.close()}
          variant="text">
          {LABELS.CANCEL}
        </AcButton>
        <AcButton onClick={handleShare}>{LABELS.SHARE_MESSAGE}</AcButton>
      </AcRow>
    </>
  )
})
