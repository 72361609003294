import type {
  IAllergy,
  IPreferenceEnum,
  IRoleEnum,
  IUser,
  IWeekDay,
} from '@typings'
import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'

export class UserApi extends AxiosBaseApi {
  // --- GET ---
  getSingle = (id: IUser['id']) =>
    this.api
      .get<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USERS.SINGLE(id))
      .then(({ data }) => data.data)

  whoAmI = () =>
    this.api
      .get<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USER.WHO_AM_I)
      .then(({ data }) => data.data)

  getPreference = () =>
    this.api
      .get<AxiosResponse<{ data: IPreferenceEnum }>>(ENDPOINTS.USER.PREFERENCE)
      .then(({ data }) => data.data)

  getRoles = () =>
    this.api
      .get<AxiosResponse<{ data: IRoleEnum[] }>>(ENDPOINTS.USER.ROLES)
      .then(({ data }) => data.data)

  getAllergies = () =>
    this.api
      .get<AxiosResponse<{ data: IAllergy['id'][] }>>(ENDPOINTS.USER.ALLERGIES)
      .then(({ data }) => data.data)

  getDefaultAttendance = () =>
    this.api
      .get<
        AxiosResponse<{ data: IWeekDay[] }>
      >(ENDPOINTS.USER.DEFAULT_ATTENDANCE)
      .then(({ data }) => data.data)

  // --- PATCH ---
  patchDefaultAttendance = (payload: { default_days: IWeekDay[] }) =>
    this.api
      .patch<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USER.PROFILE, payload)
      .then(({ data }) => data.data)

  patchPreferences = (payload: { preference: IPreferenceEnum }) =>
    this.api
      .patch<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USER.PROFILE, payload)
      .then(({ data }) => data.data)

  patchAllergies = (payload: { allergies: IAllergy['id'][] }) =>
    this.api
      .patch<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USER.PROFILE, payload)
      .then(({ data }) => data.data)

  patchOnboardingComplete = (payload: { intake_completed: boolean }) =>
    this.api
      .patch<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USER.PROFILE, payload)
      .then(({ data }) => data.data)

  // --- POST ---
  postWeeklyAttendance = (payload: {
    year: number
    week: number
    default_days: IWeekDay[]
  }) =>
    this.api
      .post<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USER.PROFILE, payload)
      .then(({ data }) => data.data)
}
