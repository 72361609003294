import { AcCard, AcColumn, AcTable, AcTypography } from '@components'

export const AcOrderDetailsMenuTab = () => {
  return (
    <AcColumn>
      <AcCard
        cardColor="beige"
        sx={{ textAlign: 'center' }}>
        <AcTypography>Some menu, not sure yet what kind of markup</AcTypography>
      </AcCard>
      <AcTable
        head={['Producten per dag']}
        rows={[
          <AcTable.Row>
            <AcTable.Cell>
              <AcTypography
                color="green.main"
                component="span"
                bold>
                Maandag 17 Juni
              </AcTypography>
            </AcTable.Cell>
          </AcTable.Row>,
          <AcTable.Row>
            <AcTable.Cell>
              <AcTypography
                color="green.main"
                component="span"
                bold>
                Dinsdag 18 Juni
              </AcTypography>
            </AcTable.Cell>
          </AcTable.Row>,
        ]}
      />
    </AcColumn>
  )
}
