import { AcBox, AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcLuncherDefaultAttendanceForm } from '@partials'

export const AcLuncherDefaultAttendanceModal = () => {
  return (
    <AcColumn
      height="100%"
      flexGrow={1}>
      <AcTypography>{LABELS.WHEN_WILL_YOU_ATTEND_SUBTITLE}</AcTypography>
      <AcLuncherDefaultAttendanceForm nextStep={false} />
    </AcColumn>
  )
}
