import { Grid, GridProps } from '@mui/material'

type Omitted = 'container' | 'item'

export interface IAcGridItem extends Omit<GridProps, Omitted> {}

export const AcGridItem = ({ children, ...props }: IAcGridItem) => {
  return (
    <Grid
      item
      {...props}>
      {children}
    </Grid>
  )
}
