import { AcTextInput, IAcTextInput } from '@components'

export const AcTextarea = ({ ...props }: IAcTextInput) => {
  return (
    <AcTextInput
      multiline
      fullWidth
      minRows={2}
      {...props}
    />
  )
}
