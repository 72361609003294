import {
  AcButton,
  AcCard,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
  AcRow,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

export const AcChangePasswordForm = () => {
  const { dialog } = useStore()

  const onSubmit = (values: any) => {
    console.log(values)
  }

  return (
    <AcFormProvider
      initial={[]}
      onSubmit={onSubmit}>
      <AcColumn>
        <AcFormInput
          register="current_password"
          Component={
            <AcPasswordInput
              label={LABELS.CURRENT_PASSWORD}
              required
            />
          }
        />
        <AcFormInput
          register="new_password"
          Component={
            <AcPasswordInput
              label={LABELS.NEW_PASSWORD}
              allowVisible={true}
              required
            />
          }
        />
        <AcFormInput
          register="new_password_confirm"
          Component={
            <AcPasswordInput
              label={LABELS.CONFIRM_NEW_PASSWORD}
              allowVisible={true}
              required
            />
          }
        />
        <AcRow justifyContent={'space-between'}>
          <AcButton
            onClick={() => dialog.close()}
            variant="text">
            {LABELS.CANCEL}
          </AcButton>
          <AcButton>{LABELS.CHANGE_PASSWORD}</AcButton>
        </AcRow>
      </AcColumn>
    </AcFormProvider>
  )
}
