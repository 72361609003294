import { Button, styled } from '@mui/material'

export const SecondaryButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.green.main,
  '&:hover': {
    borderColor: theme.palette.black.main,
    color: theme.palette.black.main,
  },
  '&.Mui-disabled': {
    borderColor: theme.palette['grey-20'].main,
    color: theme.palette['grey-20'].contrastText,
  },
}))
